export const kardesKiskancligi = {
    pageTitle:"Kardeş Kıskançlığı",
    pageDescription:"Kardeş Kıskançlığı Eğitim Serisi (10 Video)",
    videos:[
        {
            videoTitle:"Video 1 - Kardeş Kıskançlığı Nedir Özünde Ne Yatar",
            videoUrl:"https://www.youtube.com/embed/LqzvTgfdA5s"
        },
        {
            videoTitle:"Video 2 - Kardeş Kıskançlığı Yaşayan Çocukların Davranışları Nelerdir",
            videoUrl:"https://www.youtube.com/embed/vKVCbZ5V90Q"
        },
        {
            videoTitle:"Video 3 - Ebeveynlerin Fark Etmeden Kardeş Kıskançlığını Geliştiren Davranışları Nelerdir 1",
            videoUrl:"https://www.youtube.com/embed/wWwa94NJipU"
        },
        {
            videoTitle:"Video 4 - Ebeveynlerin Fark Etmeden Kardeş Kıskançlığını Geliştiren Davranışları Nelerdir 2",
            videoUrl:"https://www.youtube.com/embed/rr6ROMm6Qeo"
        },
        {
            videoTitle:"Video 5 - Evdeki Çocuğa Kardeş İster Misin Diye Sorulmalı Mı",
            videoUrl:"https://www.youtube.com/embed/PCEzIpqQqZM"
        },
        {
            videoTitle:"Video 6 - Ebeveynlerin Fark Etmeden Kardeş Kıskançlığını Geliştiren Davranışları Nelerdir 3",
            videoUrl:"https://www.youtube.com/embed/VbR0Wy0lOX0"
        },
        {
            videoTitle:"Video 7 - Aile Türleri ile Kardeş Kıskançlığı İlişkisi Nedir",
            videoUrl:"https://www.youtube.com/embed/7S5MpdImQnY"
        },
        {
            videoTitle:"Video 8 - Kardeş Kıskançlığının Ebeveynlere Bir Faydası Var Mıdır",
            videoUrl:"https://www.youtube.com/embed/WeJEq4sAai8"
        },
        {
            videoTitle:"Video 9 - Kardeş Kıskançlığının Oluşmasını Engelleyen Davranışlar Nelerdir",
            videoUrl:"https://www.youtube.com/embed/dO0iyaM2958"
        },
        {
            videoTitle:"Video 10 - Kardeşi Olacak Çocuk Nasıl Bilgilendirilmelidir",
            videoUrl:"https://www.youtube.com/embed/XpyWt8i7B0Q"
        }
    ]
}

export const yalansoyleme = {
    pageTitle:"Yalan Söyleme",
    pageDescription:"Yalan Söyleme Eğitim Serisi (9 Video)",
    videos:[
        {
            videoTitle:"Video 1 - Yalan Söylemenin Farklı Türleri Nelerdir?",
            videoUrl:"https://www.youtube.com/embed/e9qXmZpAmLc"
        },
        {
            videoTitle:"Video 2 - Sözde Yalanlar Nedir? 5 Yaş Sonrası Söylenen Yalan ile Farkı Nedir?",
            videoUrl:"https://www.youtube.com/embed/du5tbOnj-Z0"
        },
        {
            videoTitle:"Video 3 - Çocuklar Neden Yalan Söyler?",
            videoUrl:"https://www.youtube.com/embed/cUV7MwQrX7A"
        },
        {
            videoTitle:"Video 4 - Koşullu Sevgi ile Yalan Söylemek ile Arasındaki İlişki Nedir?",
            videoUrl:"https://www.youtube.com/embed/fucWMPJ1CNo"
        },
        {
            videoTitle:"Video 5 - Ebeveynlerin Hangi Tutumları Yalan Söylemeyi Teşvik Eder?",
            videoUrl:"https://www.youtube.com/embed/oSIC8bClwro"
        },
        {
            videoTitle:"Video 6 - Çocuk Yalan Söylediğinde Nasıl Ebeveynler Nasıl Davranmalı?",
            videoUrl:"https://www.youtube.com/embed/2pA3XSNBEuY"
        },
        {
            videoTitle:"Video 7 - 0 5 Yaş Arası Çocukların Fantezi Dünyası Karşısında Ebeveynler Nasıl Davranmalı?",
            videoUrl:"https://www.youtube.com/embed/EKgTSapghKY"
        },
        {
            videoTitle:"Video 8 - Çocuğun Yalan Söylemekten Vazgeçmesi İçin Ebeveynin Dönüştürmesi Gereken 4 Kavram",
            videoUrl:"https://www.youtube.com/embed/6OLH-Gyar7g"
        },
        {
            videoTitle:"Video 9 - Çocuğun Yalan Söyleme İhtiyacı Altındaki Sebepleri Nasıl Anlarız?",
            videoUrl:"https://www.youtube.com/embed/WdZ_lGV3YBc"
        }
    ]
}


export const ebeveyenliginPrensibi = {
    pageTitle:"Ebeveynliğin 7 Temel Prensibi",
    pageDescription:"Bu modülümüzde ebeveynlik prensiplerinden bahsedeceğiz. Modülümüzde 8 video bulunmaktadır.",
    videos:[
        {
            videoTitle:" Video 1 - UYGULAMA İÇERİSİNDE ANNE BABA OKULU NASIL ÇALIŞACAK.",
            videoUrl:"https://www.youtube.com/embed/SE07-fTkW0k"
        },
        {
            videoTitle:"Video 2 - ANNE BABA OKULUNUN BİRİNCİ TEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/G9q1k2Jnkdo"
        },
        {
            videoTitle:"Video 3 - ANNE BABA OKULUNUN İKİNCİ TEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/Zd_YoQ5saw8"
        },
        {
            videoTitle:" Video 4 - ANNE BABA OKULUNUN ÜÇÜNCÜ TEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/JMQZQUFArdg"
        },
        {
            videoTitle:" Video 5 - ANNE BABA OKULUNUN DÖRDÜNCÜ TEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/5rKZyOWMyaU"
        },
        {
            videoTitle:" Video 6 - ANNE BABA OKULUNUN BEŞİNCİTEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/2zJmmDyt30s"
        },
        {
            videoTitle:"Video 7 - ANNE BABA OKULUNUN ALTINCI TEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/tg5KWkWFteg"
        },
        {
            videoTitle:"Video 8 - ANNE BABA OKULUNUN YEDİNCİ TEMEL PRENSİBİ NEDİR?",
            videoUrl:"https://www.youtube.com/embed/h5A5eH3kUH4"
        }
    ]
}

export const cocukOzguven = {
    pageTitle:"Çocuğunuzda Özgüven Oluşturun",
    pageDescription:"Bu modülümüzde ‘özgüven’ konusunu işleyeceğiz. “Çocuklarda nasıl özgüven oluşturulur?” sorusunu yanıtlayacağız ve somut adımlardan konuşacağız. Modülümüzde 10 video bulunmaktadır.",
    videos:[
        {
            videoTitle:"Video 1 - Özgüven Kavramının Birinci Temeli Öz Değer Nedir?",
            videoUrl:"https://www.youtube.com/embed/9kjbCJs_120"
        },
        {
            videoTitle:"Video 2 - Özgüvenin Temelleri Nelerdir? Bu Temeller Neden Önemlidir?",
            videoUrl:"https://www.youtube.com/embed/eE89e4HGs0A"
        },
        {
            videoTitle:"Video 3 - Çocuğun Öz Değerini Bozan Mekanizmalar Nelerdir?",
            videoUrl:"https://www.youtube.com/embed/Z2eFCPOdlhM"
        },
        {
            videoTitle:"Video 4 - Çocuğun Öz Değerini Korumak İçin Neler Yapılabilir?",
            videoUrl:"https://www.youtube.com/embed/L4iIkq25eW0"
        },
        {
            videoTitle:"Video 5 - Özgüven Kavramının İkinci Temeli  Öz Yeterlilik Nedir?",
            videoUrl:"https://www.youtube.com/embed/kfupIqgNy9c"
        },
        {
            videoTitle:"Video 6 - Çocuğun Öz Yeterliliğini Bozan Mekanizmalar Nelerdir? Öz Yeterlilik Nasıl Arttırılabilir?",
            videoUrl:"https://www.youtube.com/embed/y7Ljr6VuKkI"
        },
        {
            videoTitle:"Video 7 - Öz Yeterlilik Becerisi Çocuğa Nasıl Kazandırılabilir?",
            videoUrl:"https://www.youtube.com/embed/BE3rIC0evFY"
        },
        {
            videoTitle:"Video 8 - Öz Değer Neden Öz Yeterlilikten Daha Önemlidir?",
            videoUrl:"https://www.youtube.com/embed/_j1sfNOC63Y"
        },
        {
            videoTitle:"Video 9 - Özgüven Kavramının Üçüncü Temeli  Özerklik Nedir?",
            videoUrl:"https://www.youtube.com/embed/CDHVRMurSAg"
        },
        {
            videoTitle:"Video 10 - Tüm Alt Kavramları İle Özgüven ve Çocuğa Özgüven Kazandırmanın Yolları Nelerdir?",
            videoUrl:"https://www.youtube.com/embed/4SeCwjA0PkE"
        }
    ]
}


