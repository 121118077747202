import { useEffect } from "react";
import AOS from "aos";

const QuestionCards = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div class="page">
      <div class="download">
        <div class="download__center center">
          <div class="stage">Parentwiser</div>
          <div class="download__body">
            <div className="offer__container h4" style={{ color: "white" }}>
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
                Sohbet Kartları
              </h3>
            </div>
            <div class="review__center" style={{ marginTop: 40 }}>
              <div class="download__info">
                Sor Bana Oyunu, eğitim bilimci Dr. Özgür Bolat ve klinik
                psikologlardan oluşan ekibi danışmanlığında hazırlanmıştır. Oyun
                içerisinde 500 adet soru kartı bulunuyor. Kartların amacı; aile
                bireyleri arasındaki bağları güçlendirmek, sohbetlerin
                niteliğini ve derinliğini artırmak, bireylerin birbirleriyle
                daha fazla ve keyifli vakit geçirmesini sağlamaktır. Bunların
                yanında belirli konular üzerinde fikir yürütmeye, derinlemesine
                düşünmeye ve hayal gücünü geliştirmeye imkan tanımak, kişisel
                farkındalıklar ve yeni bakış açıları kazandırmak kartların
                hedeflediği önemli kazanımlardandır.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionCards;
