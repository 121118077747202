/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import images from "../../img/index";
import "../GlobalCss/globalStyle.css";
import DownloadModal from "./components/DownloadModal";

export default function Header() {
  const [darkActive, setDarkActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    var darkThemeActive = localStorage.getItem("darkMode");

    switch (darkThemeActive) {
      case "on":
        setDarkActive(true);
        break;
      case "off":
        setDarkActive(false);
        break;
      default:
        setDarkActive(false);
        break;
    }
  }, []);

  let handleThemeChange = () => {
    var darkThemeActive = localStorage.getItem("darkMode");

    // eslint-disable-next-line no-undef
    var switchTheme = $(".js-switch-theme"),
      body = $("body");

    switch (darkThemeActive) {
      case "off":
        setDarkActive(true);
        body.addClass("dark");
        localStorage.setItem("darkMode", "on");
        break;
      case "on":
        setDarkActive(false);
        body.removeClass("dark");
        localStorage.setItem("darkMode", "off");
        break;
      default:
        break;
    }
  };

  let burgerMenuClick = () => {
    var header = $(".header"),
      wrapper = header.find(".header__wrapper"),
      burger = header.find(".header__burger");

    burger.toggleClass("active");
    wrapper.toggleClass("visible");
    $("html").toggleClass("no-scroll");
    $("body").toggleClass("no-scroll");
  };

  let downloadModal = () => {
    var header = $(".header"),
      wrapper = header.find(".header__wrapper"),
      burger = header.find(".header__burger");

    burger.toggleClass("active");
    wrapper.toggleClass("visible");
    setModalOpen(true);
  };

  return (
    <header className="header">
      <div className="header__center centerDuplicated">
        <a className="header__logo" href="home">
          <img
            className="header__pic header__pic_dark"
            src={images.logoDark}
            alt=""
          />
          <img
            className="header__pic header__pic_light"
            src={images.logoDark}
            alt=""
          />
        </a>
        <div className="header__wrapper">
          <nav className="header__nav">
            <a className="header__link" href="/training">
              Ebeveyn Çözümleri
            </a>
            <a className="header__link" href="/parentSchool">
              Anne Baba Okulu
            </a>
            <a className="header__link" href="/knowYourself">
              Anketler
            </a>
            <a className="header__link" href="/questionCards">
              Sohbet Kartları
            </a>
            {/* <a
              className="header__link"
              style={{ cursor: "pointer" }}
              onClick={downloadModal}
            >
              İndir
            </a> */}
            <a className="header__link" href="/contact">
              İletişim
            </a>
            <a
              className="header__link"
              href="https://parentwiser.notion.site/parentwiser/ParentWiser-Career-f79230a20efb4cf59c94117b5035be56"
              target="_blank"
            >
              Kariyer
            </a>
          </nav>
          {/* <div className="header__btns">
            <label class="switch js-switch-theme">
              <input
                class="switch__input"
                type="checkbox"
                onChange={handleThemeChange}
                checked={darkActive}
              />
              <span class="switch__in">
                <span class="switch__box"></span>
                <span class="switch__icon switch__icon_dark">
                  <svg class="icon icon-dark">
                    <use xlinkHref="img/sprite.svg#icon-dark"></use>
                  </svg>
                </span>
                <span class="switch__icon switch__icon_light">
                  <svg class="icon icon-light">
                    <use xlinkHref="img/sprite.svg#icon-light"></use>
                  </svg>
                </span>
              </span>
            </label>
          </div> */}
          <div class="header__btns">
            <a
              class="header__btn btn btn_transparent js-popup-open"
              data-effect="mfp-zoom-in"
              href="http://app.parentwiser.com/login"
              target="_blank"
            >
              Giriş
            </a>
            <a
              class="header__btn btn btn_blue js-popup-open"
              href="http://app.parentwiser.com/register"
              target="_blank"
              data-effect="mfp-zoom-in"
            >
              Kayıt Ol
            </a>
          </div>
          <div className="header__preview">
            {/* <img style={{ maxWidth: "767px" }} src={pwLogo} alt="" />
            <img srcset="img/header-pic@2x.png 2x" src={pwLogo} alt="" /> */}
          </div>
        </div>
        <button className="header__burger" onClick={burgerMenuClick}></button>
      </div>
      <DownloadModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </header>
  );
}
