import React, { useEffect } from "react";
import AOS from "aos";
import {surveyList,sampleSurveys} from '../../data/know.yourself.page'

const KnowYourself = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="page">
      <div className="download">
        <div className="download__center center">
          <div className="stage">Parentwiser</div>
          <div className="download__body">
            <div className="offer__container h4" style={{color:"white"}}>
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
                Anketler
              </h3>
            </div>
            <div className="review__center" style={{ marginTop: 40 }}>
              <div className="download__info">
                Ebeveynlerin kendilerini geliştirmeleri için kendilerini
                tanımaları oldukça önemlidir. Örneğin, bir çocuk hırslıysa, anne
                ve/veya baba çocuğu eleştiriyordur, övüyordur veya başarı
                baskısı yapıyordur. Bu sorunu çözmek için ailenin ilk önce
                bilimsel anketlerimizi çözüp, çocuktaki hırsı yaratan
                kendilerindeki etkeni bulması gerekir. Anne ve baba, kendi
                davranışlarını değiştirince çocuklardaki birçok sorun
                çözülecektir. Ayrıca anketlerimiz sayesinde çocuğunuzu da daha
                yakından tanıyacaksınız. Örneğin, topluluk içinde çekingen kalan
                bir çocuk ‘utangaç’ mı yoksa ‘içe dönük’ olduğu için mi böyle
                davranıyor keşfedeceksiniz. Uygulamamızda bilimsel anketlerimizi
                doldurduktan sonra, sizlere raporlar sunuyoruz. Bu raporlar
                sayesinde hem kendinizle hem de çocuğunuzla ilgili farkındalık
                kazanıp, sorunlara daha bilinçli yaklaşabilirsiniz.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="download">
        <div className="download__center center">
          <div className="stage">Parentwiser</div>
          <h1
            className="download__title h3"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Uygulama İçerisinde Bulunacak Anketlerimiz
          </h1>
          <div className="download__info">
            Aşağıdaki anketlere yıl boyunca yeni anketler eklenecek ve
            ebeveynlerin her açıdan kendilerini ve çocuklarını tanıma fırsatı
            sunulacaktır.
          </div>
          <div className="download__body">
            <div className="download__list">
              {surveyList.map(
                (element) => {
                  return (
                    <a className="download__item">
                      <div className="download__category">{element}</div>
                    </a>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Sample Surveys */}
      <div className="pricing">
        <div className="pricing__center center">
          <h1
            className="pricing__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Bilimsel Anketlerimizden Örnekler
          </h1>
          <div className="pricing__info">
          
          </div>
          <div className="pricing__list">
            {sampleSurveys.map(
              (element) => {
                return (
                  <div className="pricing__item">
                    <div className="pricing__head">
                      <div
                        className="pricing__subtitle"
                        data-aos="animation-scale-top"
                        data-aos-duration="600"
                        data-aos-delay="200"
                      >
                        {element.title}
                      </div>
                      <div className="pricing__text">{element.subtitle}</div>
                      <button
                        onClick={() =>
                          (window.location.href = `/survey?id=${element.id}`)
                        }
                        className="pricing__btn btn btn_orange"
                      >
                        Anketi Gör
                      </button>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowYourself;
