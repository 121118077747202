import React, { useEffect } from "react";
import "../../components/GlobalCss/globalStyle.css";
import "../../components/GlobalCss/fontStyle.css";
import AOS from "aos";
import socialImages from "../../img/social/index";
import images from "../../img/index";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

export default function Home() {
  useEffect(() => {
    if (localStorage.getItem("darkMode") === "on") {
      document.body.classList.add("dark");
      document.addEventListener("DOMContentLoaded", function () {
        document.querySelector(".js-switch-theme input").checked = true;
      });
    }
    AOS.init();
  }, []);

  const commentSliderSettings = {
    lazyload: true,
    nav: true,
    mouseDrag: true,
    controls: false,
    mode: "gallery",
    responsive: {
      619: {
        items: 1,
      },
      620: {
        items: 2,
      },

      900: {
        items: 3,
      },
    },
  };

  return (
    <div className="page">
      <div className="main">
        <div className="main__center center">
          <div className="main__wrap">
            <div className="stage">KİŞİYE ÖZGÜ ÇOCUK YETİŞTİRME REHBERİ</div>
            <h1 className="main__title d1">
              <span data-aos="animation-scale-top" data-aos-duration="600">
                Türkiye'nin
              </span>
              <span
                data-aos="animation-scale-top"
                data-aos-duration="600"
                data-aos-delay="200"
              >
                Ebeveynlik{" "}
              </span>
              <span
                data-aos="animation-scale-top"
                data-aos-duration="600"
                data-aos-delay="400"
              >
                Uygulaması
              </span>
            </h1>
            <div className="main__text">
              Parentwiser'ı ücretsiz şekilde indirebilirsiniz.
            </div>
            <div className="main__btns">
              <img
                src={images.appStoreSvg}
                style={{cursor:"pointer"}}
                alt=""
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/tr/app/parentwiser/id1527425284",
                    "_blank"
                  )
                }
              />
              <img
                className="button_ml"
                src={images.googlePlaySvg}
                style={{cursor:"pointer",}}
                alt=""
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.parentwiser.parentingapp.android&hl=tr&gl=US",
                    "_blank"
                  )
                }
              />
            </div>
            <div style={{
              marginTop:"20px",
              alignItems:"start",
              display:"flex",
              textAlign:"center",
            }}>
              <img src={images.startYellow} alt="" />
              <img src={images.startYellow} alt="" />
              <img src={images.startYellow} alt="" />
              <img src={images.startYellow} alt="" />
              <img src={images.startYellowHalf} alt="" />
              <div className="main__text" style={{marginLeft:"20px"}}>
                4.8 Geri Bildirim 
              </div>

            </div>
          </div>
          <div className="main__bg" data-aos="">
            <div className="main__preview">
              <img
                srcSet={`${images.phoneMockUp2x} 2x`}
                src={images.phoneMockUp}
                alt=""
              />
            </div>
            <div className="main__preview">
              <img
                srcSet={`${images.messageBoxMockUp2x} 2x`}
                src={images.messageBoxMockUp}
                alt=""
              />
            </div>
          </div>
          <div className="main__partners">
            <div className="main__info">
              Sosyal medya hesaplarımızdan bizi takip etmeyi unutmayın.
            </div>
            <div className="main__list">
              {/* <div className="main__logo">
                <a
                  href="https://www.facebook.com/parentwiserapp/"
                  target="_blank"
                >
                  <img src={socialImages.facebook} alt="" />
                </a>
              </div> */}
              <div className="main__logo">
                <a
                  href="https://www.instagram.com/parentwiser/"
                  target="_blank"
                >
                  <img src={socialImages.instagram} alt="" style={{cursor:"pointer"}}/>
                </a>
              </div>
              <div className="main__logo">
                <a
                  href="https://www.youtube.com/@ParentWiserTR"
                  target="_blank"
                >
                  <img src={socialImages.youtube} alt="" style={{cursor:"pointer"}}/>
                </a>
              </div>
              {/* <div className="main__logo">
                <a href="https://www.twitter.com/parentwiser" target="_blank">
                  <img src={socialImages.twitter} alt="" />
                </a>
              </div> */}
              <div className="main__logo">
                <a
                  href="https://www.linkedin.com/company/parentwiser/"
                  target="_blank"
                >
                  <img src={socialImages.linkedin} alt="" style={{cursor:"pointer"}}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* QUALITY */}
      <div className="quality">
        <div className="quality__center center">
          <div className="quality__body">
            <div className="quality__bg"></div>
            <div className="quality__gallery">
              <div className="quality__preview">
                <img
                  srcSet={`${images.staircase2x} 2x`}
                  src={images.staircase}
                  alt=""
                />
              </div>
              <div
                className="quality__preview"
                data-aos="animation-translate-up"
                data-aos-duration="1000"
              >
                <img
                  srcSet={`${images.straightPhone2x} 2x`}
                  src={images.straightPhone}
                  alt=""
                />
              </div>
              <div
                className="quality__preview"
                data-aos="animation-translate-down"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <img
                  srcSet={`${images.reverseMessageBox2x} 2x`}
                  src={images.reverseMessageBox}
                  alt=""
                />
              </div>
              <div
                className="quality__preview"
                data-aos="animation-translate-down"
                data-aos-duration="1000"
                data-aos-delay="800"
              >
                <img
                  srcSet={`${images.smallMessageBox2x} 2x`}
                  src={images.smallMessageBox}
                  alt=""
                />
              </div>
              <div className="quality__preview">
                <img
                  srcSet="img/figures-2.5@2x.png 2x"
                  src="img/figures-2.5.png"
                  alt=""
                />
              </div>
            </div>
            <div className="quality__wrap">
              <h2
                className="quality__title h2"
                data-aos="animation-scale-top"
                data-aos-duration="600"
              >
                "Çocuk yetiştirmek, kendini yetiştirmektir."
              </h2>
              <div className="quality__text">
                Sizin için hazırladığımız ücretsiz içeriklerin keyfini
                çıkarın...
              </div>
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/tr/app/parentwiser/id1527425284",
                    "_blank"
                  )
                }
                className="quality__btn btn btn_border"
              >
                App Store
              </button>
              &nbsp; &nbsp;
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.parentwiser.parentingapp.android&hl=tr&gl=US",
                    "_blank"
                  )
                }
                className="quality__btn btn btn_border"
              >
                Google Play
              </button>
            </div>
          </div>
          {/* <div className="quality__bottom">
            <div className="quality__item">
              <div className="quality__counter">
                5000<span className="quality__sign quality__sign_up">+</span>
              </div>
              <div className="quality__category">Play Store İndirme</div>
            </div>
            <div className="quality__item">
              <div className="quality__counter">
                5000<span className="quality__sign quality__sign_up">+</span>
              </div>
              <div className="quality__category">App Store İndirme</div>
            </div>
            <div className="quality__item">
              <div className="quality__counter">
                4.6<span className="quality__sign quality__sign_up">★</span>
              </div>
              <div className="quality__category">Geri Bildirim</div>
            </div>
          </div> */}
        </div>
      </div>
      {/* STEPS */}
      <div className="steps">
        <div className="steps__center center">
          <div className="stage">NASIL KULLANILIR?</div>
          <h2
            className="steps__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Basit, hızlı & zahmetsiz.
          </h2>
          <div className="steps__list">
            <div className="steps__item">
              <div className="steps__icon">
                <img src={images.pwDownloadSvg} alt="" />
              </div>
              <div className="steps__number">Adım 1</div>
              <div className="steps__text">Uygulamayı indir</div>
            </div>
            <div className="steps__item">
              <div className="steps__icon">
                <img src={images.pwRegistersSvg} alt="" />
              </div>
              <div className="steps__number">Adım 2</div>
              <div className="steps__text">Kayıt ol</div>
            </div>
            <div className="steps__item">
              <div className="steps__icon">
                <img src={images.pwPersonalInfoSvg} alt="" />
              </div>
              <div className="steps__number">Adım 3</div>
              <div className="steps__text">Kişisel bilgilerini gir</div>
            </div>
            <div className="steps__item">
              <div className="steps__icon">
                <img src={images.kidInfoSvg} alt="" />
              </div>
              <div className="steps__number">Adım 4</div>
              <div className="steps__text">Çocuğunun bilgilerini gir</div>
            </div>
          </div>
        </div>
      </div>
      {/* ADVANTAGES */}
      <div className="advantages">
        <div className="advantages__center center">
          <div className="advantages__head">
            <div className="stage">
              Uygulamamız üç ana bölümden oluşmaktadır.
            </div>
            <h2
              className="advantages__title h2"
              data-aos="animation-scale-top"
              data-aos-duration="600"
            >
              Parentwiser’ın Temel Fonksiyonları
            </h2>
          </div>
          <div className="advantages__list">
            <div className="advantages__item">
              <div className="advantages__bg">
                <div className="advantages__preview">
                  <img
                    srcSet={`${images.knowYourselfPng2x} 2x`}
                    src={images.knowYourselfPng}
                    alt=""
                  />
                </div>
              </div>
              <div className="advantages__wrap">
                <div
                  className="advantages__icon"
                  data-aos="animation-scale-top"
                  data-aos-duration="800"
                ></div>
                <div
                  className="advantages__info"
                  data-aos="animation-scale-top"
                  data-aos-duration="800"
                  data-aos-delay="400"
                >
                  Anketler
                </div>
                <div className="advantages__text">
                  Çocuğunuzla ilgili sorunları çözmeden önce kendinizi ve
                  çocuğunuzu bilimsel anketler aracılığıyla tanıyın.
                </div>
                <button
                  onClick={() => (window.location.href = "/knowYourself")}
                  className="advantages__btn btn btn_border"
                >
                  Detaylar
                </button>
              </div>
            </div>
            <div className="advantages__item">
              <div className="advantages__bg">
                <div className="advantages__preview">
                  <img
                    srcSet={`${images.trainingsPng2x} 2x`}
                    src={images.trainingsPng}
                    alt=""
                  />
                </div>
              </div>
              <div className="advantages__wrap">
                <div
                  className="advantages__icon"
                  data-aos="animation-scale-top"
                  data-aos-duration="800"
                ></div>
                <div
                  className="advantages__info"
                  data-aos="animation-scale-top"
                  data-aos-duration="800"
                  data-aos-delay="400"
                >
                  Ebeveyn Çözümleri
                </div>
                <div className="advantages__text">
                  Çocuğunuzla yaşadığınız veya çocuğunuzda çözmek istediğiniz
                  sorunlarla ilgili çözüm odaklı eğitimler alın.
                </div>
                <button
                  onClick={() => (window.location.href = "/training")}
                  className="advantages__btn btn btn_border"
                >
                  Detaylar
                </button>
              </div>
            </div>
            <div className="advantages__item">
              <div className="advantages__bg">
                <div className="advantages__preview">
                  <img
                    srcSet={`${images.parentSchoolPng2x} 2x`}
                    src={images.parentSchoolPng}
                    alt=""
                  />
                </div>
              </div>
              <div className="advantages__wrap">
                <div
                  className="advantages__icon"
                  data-aos="animation-scale-top"
                  data-aos-duration="800"
                >
                  <img src="" alt="" />
                </div>
                <div
                  className="advantages__info"
                  data-aos="animation-scale-top"
                  data-aos-duration="800"
                  data-aos-delay="400"
                >
                  Anne Baba Okulu
                </div>
                <div className="advantages__text">
                  Çocuğunuzla sorunlar yaşamasanız da daha yetkin bir ebeveyn
                  olmak veya sorunların çıkmasını engellemek için kendinizi
                  geliştirin.
                </div>
                <button
                  onClick={() => (window.location.href = "/parentSchool")}
                  className="advantages__btn btn btn_border"
                >
                  Detaylar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OPTIONS */}
      <div className="options">
        <div className="options__center center">
          <div className="advantages__head">
            <h2
              className="advantages__title h2"
              data-aos="animation-scale-top"
              data-aos-duration="600"
            >
              Parentwiser’ın Temel Özellikleri
            </h2>
          </div>
          <div className="options__wrap">
            <div className="options__list">
              <div className="options__item">
                <div className="options__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="48"
                    viewBox="0 0 46 48"
                  >
                    <path
                      fill="#272e35"
                      d="M7 34.5a6.5 6.5 0 1 1 0 13 6.5 6.5 0 1 1 0-13zm0-34A6.5 6.5 0 0 1 13.5 7c0 3.073-2.133 5.648-4.999 6.326v4.348a6.51 6.51 0 0 1 4.825 4.826h3.348c.677-2.867 3.252-5 6.326-5a6.5 6.5 0 0 1 6.5 6.5 6.47 6.47 0 0 1-1.073 3.579l7.329 7.787A6.47 6.47 0 0 1 39 34.5a6.5 6.5 0 1 1 0 13c-3.073 0-5.648-2.133-6.326-4.999h-3.348C28.648 45.367 26.073 47.5 23 47.5a6.5 6.5 0 1 1 0-13c3.074 0 5.649 2.133 6.326 5h3.348c.178-.752.485-1.453.899-2.078l-7.33-7.787A6.47 6.47 0 0 1 23 30.5c-3.073 0-5.648-2.133-6.326-4.999h-3.348C12.648 28.367 10.073 30.5 7 30.5A6.5 6.5 0 0 1 .5 24c0-3.074 2.133-5.649 5-6.326v-4.348C2.633 12.649.5 10.074.5 7A6.5 6.5 0 0 1 7 .5zm0 37a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm16 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm16 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm0-20a6.5 6.5 0 1 1 0 13 6.5 6.5 0 1 1 0-13zm-32 3a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm16 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm16 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zM23 .5a6.5 6.5 0 1 1 0 13 6.5 6.5 0 1 1 0-13zm16 0a6.5 6.5 0 1 1 0 13 6.5 6.5 0 1 1 0-13zm-32 3a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm16 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7zm16 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 1 0 0-7z"
                    ></path>
                  </svg>
                </div>
                <div className="options__info">
                  Bilimsel ve <br />
                  Güvenilir Bilgi
                </div>
                <div className="options__text"></div>
                <div className="advantages__text">
                  Birçok ebeveyn bilgi kirliliğinin içinde kaybolup, güvenilir
                  ve bilimsel bilgiye ulaşmakta zorlandığını ifade etmektedir.
                </div>
              </div>
              <div className="options__item">
                <div className="options__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                  >
                    <path
                      fill="#272e35"
                      d="M18 .5C27.665.5 35.5 8.335 35.5 18a17.42 17.42 0 0 1-2.448 8.932L43.06 36.939a1.5 1.5 0 0 1 .103 2.007l-.103.114-4 4a1.5 1.5 0 0 1-2.007.103l-.114-.103-10.007-10.008A17.42 17.42 0 0 1 18 35.5C8.335 35.5.5 27.665.5 18S8.335.5 18 .5zm13.278 28.9a17.61 17.61 0 0 1-1.879 1.879l8.6 8.601L39.879 38zM18 3.5C9.992 3.5 3.5 9.992 3.5 18S9.992 32.5 18 32.5c3.078 0 5.931-.959 8.279-2.594.012-.012.026-.021.039-.029C30.057 27.255 32.5 22.913 32.5 18c0-8.008-6.492-14.5-14.5-14.5z"
                    ></path>
                  </svg>
                </div>
                <div className="options__info">
                  Hızlı ve Kolay <br />
                  Erişim
                </div>
                <div className="options__text"></div>
                <div className="advantages__text">
                  İnternet dünyasında güvenilir bilgi olsa bile, birçok ebeveyn
                  bu bilgilere hızlı ve kolay ulaşamadığını ifade etmektedir.
                </div>
              </div>
              <div className="options__item">
                <div className="options__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                  >
                    <path
                      fill="#272e35"
                      d="M15.5 36A7.52 7.52 0 0 1 8 43.5 7.52 7.52 0 0 1 .5 36 7.52 7.52 0 0 1 8 28.5h4.5v-13H8A7.52 7.52 0 0 1 .504 8.242L.5 8A7.52 7.52 0 0 1 8 .5 7.52 7.52 0 0 1 15.5 8h0v4.5h13V8A7.52 7.52 0 0 1 35.758.504L36 .5A7.52 7.52 0 0 1 43.5 8a7.52 7.52 0 0 1-7.5 7.5h0-4.5v13H36a7.52 7.52 0 0 1 7.496 7.258L43.5 36a7.52 7.52 0 0 1-7.5 7.5 7.52 7.52 0 0 1-7.5-7.5h0v-4.5h-13zM36 31.5h-4.5V36a4.52 4.52 0 0 0 4.5 4.5 4.52 4.52 0 0 0 4.5-4.5 4.52 4.52 0 0 0-4.5-4.5h0zm-23.5 0H8A4.52 4.52 0 0 0 3.5 36 4.52 4.52 0 0 0 8 40.5a4.52 4.52 0 0 0 4.5-4.5h0v-4.5zm16-16h-13v13h13v-13zM8 3.5A4.52 4.52 0 0 0 3.5 8 4.52 4.52 0 0 0 8 12.5h4.5V8a4.52 4.52 0 0 0-4.289-4.495h0zm28 0A4.52 4.52 0 0 0 31.5 8h0v4.5H36a4.52 4.52 0 0 0 4.495-4.289h0L40.5 8A4.52 4.52 0 0 0 36 3.5z"
                    ></path>
                  </svg>
                </div>
                <div className="options__info">
                  Geleceğe Dair Öneri <br /> ve Bilgilendirme
                </div>
                <div className="options__text"></div>
                <div className="advantages__text">
                  İkinci etapta Parentwiser bilimsel anketlerin ve ebeveynlerin
                  inceledikleri konuları analiz ederek, ebeveynlere geleceği
                  dair ön bilgilendirmeler sağlar.
                </div>
              </div>
              <div className="options__item">
                <div className="options__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#272e35"
                      d="M24 .5C11.021.5.5 11.021.5 24S11.021 47.5 24 47.5 47.5 36.979 47.5 24 36.979.5 24 .5zm0 3c11.322 0 20.5 9.178 20.5 20.5S35.322 44.5 24 44.5 3.5 35.322 3.5 24 12.678 3.5 24 3.5z"
                    ></path>
                  </svg>
                </div>
                <div className="options__info">
                  Kişiye Özel <br /> Bilgi
                </div>
                <div className="options__text"></div>
                <div className="advantages__text">
                  Bilgilerin geçerliliği farklı yaş gruplarına göre değişkenlik
                  göstermektedir. İkinci etapta Parentwiser, kişiyi tanır ve ona
                  özgü bilgi sunar.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* PARTNERS */}
      <div className="integrations">
        <div className="integrations__center center">
          <div className="integrations__wrap">
            <div className="stage">Parentwiser</div>
            <h2
              className="integrations__title h2"
              data-aos="animation-scale-top"
              data-aos-duration="600"
            >
              İş <br /> Ortaklarımız
            </h2>
            <div className="">
              Dünyanın önde gelen markaları ile birlikte iş ortaklığı
              yapmaktayız.
            </div>
          </div>
          <div className="integrations__list">
            {/* <div className="integrations__row">
              <div className="integrations__col">
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src={images.googleSvg} alt="" />
                </div>
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src="img/figma.svg" alt="" />
                </div>
              </div>
              <div className="integrations__col">
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src="img/atom.svg" alt="" />
                </div>
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src="img/sketch.svg" alt="" />
                </div>
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src="img/google.svg" alt="" />
                </div>
              </div>
              <div className="integrations__col">
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src="img/dribbble.svg" alt="" />
                </div>
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                  style={{ paddingTop: 10, paddingLeft: 10 }}
                >
                  <img src="img/ui8.svg" alt="" />
                </div>
              </div>
            </div> */}
            <div className="integrations__row">
              <div className="integrations__col">
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src={images.googleSvg} alt="" />
                </div>
              </div>
              <div className="integrations__col">
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src={images.dovePng} alt="" />
                </div>
              </div>
              <div className="integrations__col">
                <div
                  className="integrations__logo"
                  data-aos="animation-scale-top"
                  data-aos-duration="600"
                >
                  <img src={images.ntvPng} alt="" style={{width:"130px"}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* REVIEW */}

      <div className="comment">
        <div className="comment__center center">
          <h2
            className="comment__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Ne Dediler ?
          </h2>
          <div className="comment__container">
            <TinySlider className="tinySlider" settings={commentSliderSettings}>
              <div className="comment__item">
                <div className="comment__body">
                  <div className="comment__text">
                    “Sadece ebeveynler için görünse de aynı zamanda alanda
                    calışan öğretmenler, ruh saglığı profesyonelleri için de
                    harika bir kaynak.”
                  </div>
                  <div className="comment__rating">
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                  </div>
                </div>
                <div className="comment__author">
                  <div className="comment__details">
                    <div className="comment__man">Beyza Kökçü</div>
                  </div>
                </div>
              </div>
              <div className="comment__item">
                <div className="comment__body">
                  <div className="comment__text">
                    “Çocuklarımızla sağlıklı bir iletişimin nasıl
                    yapılabileceği, ortaya çıkan krizlerin nasıl çözülebileceği
                    gibi binlerce sorunun cevabı uygulamada var.”
                  </div>
                  <div className="comment__rating">
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                  </div>
                </div>
                <div className="comment__author">
                  <div className="comment__details">
                    <div className="comment__man">Nilgün Gölgen</div>
                  </div>
                </div>
              </div>
              <div className="comment__item">
                <div className="comment__body">
                  <div className="comment__text">
                    “Bir ebeveynin boş kaldıkça kullanması gereken çok iyi bir
                    uygulama. Çocuk yetiştirmede ne kadar yanlışlar yaptığınızı
                    fark edeceksiniz”
                  </div>
                  <div className="comment__rating">
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                    <div className="comment__icon">
                      <img src={images.starFill} alt="" />
                    </div>
                  </div>
                </div>
                <div className="comment__author">
                  <div className="comment__details">
                    <div className="comment__man">Hakan Utku</div>
                  </div>
                </div>
              </div>
            </TinySlider>
          </div>
        </div>
      </div>
    </div>
  );
}
