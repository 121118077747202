export const sinavKaygisi = {
  title: "Sınav Kaygısı",
  description:
    "Bu dosyamızda 6 makale bulunmaktadır. Sadece birinci makale örneği aşağıda gösterilmiştir.",
  articles: [
    {
      articleTitle: "Makale 1: Sınav Kaygısı Nedir? (Örnek makale aşağıdadır.)",
    },
    {
      articleTitle: "Makale 2: Sınav Kaygısı Yaşayan Çocuk Neler Deneyimler?",
    },
    {
      articleTitle:
        "Makale 3: “Bizim Çocukta Sınav Kaygısı Filan Yok, Kaygılansa Çalışır.”",
    },
    {
      articleTitle:
        "Makale 4: Sınav Kaygısına Hangi Ebeveyn Davranışları Neden Olur?",
    },
    {
      articleTitle: "Makale 5: Sınav Kaygısını Önlemenin Yolları Nelerdir?",
    },
    {
      articleTitle:
        "Makale 6: Yaptığımız Hataları Fark Ettik, Şimdi Ne Yapacağız?",
    },
  ],
  previewTitle: "Sınav Kaygısı Nedir?",
  previewArticle: (
    <body>
      Kaygı korkulacak bir durumun yaşanma olasılığına dair yaşanan
      korkudur. Örneğin; üzerine hızla yaklaşan bir araba geldiğini gören
      herkes korkar. Kaygı ise daha sokağa bile çıkmadan, üzerine hızla
      yaklaşan bir arabanın gelme ihtimalini düşünüp korkmaktır. Korku şu
      ana aitken, kaygı geleceğe aittir. Yani bir durumun kişide kaygı
      yaratıp yaratmayacağı, çoğu zaman kişinin o durumu nasıl
      anlamlandırdığına bağlıdır. Sınav kaygısının iki temel sebebi vardır.
      Birincisi ve en önemlisi ‘utançtır.’ Çocuk sınavda başarılı olmazsa,
      kendinde bir sorun oluşacağını ve ailesi ve toplum tarafından kabul
      görmeyeceğini düşünür. Bu öğrencilerden sıklıkla şu cümleleri duyarız:
      “Başaramazsam ailemin yüzüne bakamam.”, “Başaramazsam ailemin ve
      öğretmenlerimin gözünden düşerim.”, “Başaramazsam rezil olurum.”,
      “Başaramazsam insanlar benim aptal olduğumu düşünür.”, “Başaramazsam
      zeki olmadığım ortaya çıkar.”, “İyi bir liseye/ üniversiteye
      giremezsem hayatım biter.”. “İyi bir liseye/ üniversiteye giremezsem
      toplumda değer görmem.”. Tüm bu cümlelerin ortak duygusu utançtır ve
      utanç insan için en yıkıcı duygulardan biridir. Kişi başarısız olursa,
      kendisinden utanacak ve değersiz hissedecektir. Bizim toplumumuzda
      utanç kaynaklı kaygı oldukça fazladır. 2015 PISA Öğrenci Refahı
      Raporu’nda, öğrencilerin %59’sı “Sınava hazır hissetsem bile kaygı
      yaşarım” demiştir. Yani maalesef bizim çocuklarımızın çoğu utanç
      temelli kaygı yaşıyor. Bu sorunu çözmek için lütfen ‘sınav kaygısı
      nasıl yönetilir?’ videolarımızı izleyin Kaygının ikinci sebebi de
      hazır olmama, yani yetersizliktir. Kişi diğer makalelerde ele
      alacağımız gibi birçok sebepten dolayı sınava hazır olmayabilir. İlk
      önce çocuk neden sınava hazır değil onu keşfetmek gerekir. Örneğin
      yeteri kadar çalışmamıştır, çalışma yöntemlerini bilmiyordur (lütfen
      çalışma yöntemleri videolarımızı izleyin), motivasyonu yoktur, sınavı
      anlamlı bulmuyordur. Bazen bir çocukta utanç ve yetersizlik olsa da ve
      bundan dolayı kaygı yaşasa da bu kaygıyı yönetebilir. Çocuklar kaygıyı
      yönetmeyi öğretmek gerekir. (Lütfen kaygı yönetimi videolarımızı
      izleyin.) Kısacası çocuklar, yetersizlik ve utançtan dolayı kaygı
      yaşarlar. İlk önce kaygının sebebini bulmak ve ona göre önlemler almak
      gerekir. Diğer makale ve videolarımızda bu konuları derinlemesine ele
      alıp sizlere çözümler önereceğiz.
    </body>
  ),
};


export const kardesKiskancligi = {
    title: "Kardeş Kıskançlığı",
    description:
      "Bu dosyamızda 15 makale bulunmaktadır. Sadece birinci makale örneği aşağıda gösterilmiştir.",
    articles: [
      {
        articleTitle: "Makale 1: Kardeş Kıskançlığı Neden Ortaya Çıkar?  (Örnek makale aşağıdadır.)",
      },
      {
        articleTitle: "Makale 2: Çocuklar Kardeş Kıskançlığını Nasıl Gösterir?",
      },
      {
        articleTitle:
          "Makale 3: Ebeveynlerin Kardeş Kıskançlığına Sebep Olan Bilinçdışı Düşünceleri Nelerdir?",
      },
      {
        articleTitle:
          "Makale 4: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Eşit Davranmak",
      },
      {
        articleTitle: "Makale 5: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Kardeşleri Kıyaslamak",
      },
      {
        articleTitle:
          "Makale 6: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Kardeşler Arasında Taraf Tutmak",
      },
      {
        articleTitle: "Makale 7: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Kardeş Yapma Fikrini Çocuğa Sormak",
      },
      {
        articleTitle: "Makale 8: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Olumsuz Kardeş Yorumu",
      },
      {
        articleTitle:
          "Makale 9: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Kardeşle İlgili Yalan Söylemek",
      },
      {
        articleTitle:
          "Makale 10: Kardeş Kıskançlığına Neden Olan Ebeveyn Davranışı: Ağabeylik ve Ablalık Rolü Vermek",
      },
      {
        articleTitle: "Makale 11: Aile Türünüz Kardeş Kıskançlığını Nasıl Etkiler?",
      },
      {
        articleTitle:
          "Makale 12: Kardeş Kıskançlığını Ortadan Kaldırmak ve Engellemek İçin Neler Yapılmalıdır?",
      },
      {
        articleTitle:
          "Makale 13: Çocuğu Yeni Doğacak Kardeşe Nasıl Hazırlarız?",
      },
      {
        articleTitle: "Makale 14: Yeni Bir Kardeş Gelirken, Çocuk Bilgilendirilmeli",
      },
      {
        articleTitle:
          "Makale 15: İkizlerde Kardeş Kıskançlığının Benzerlikleri ve Farklılıkları Nelerdir?",
      },
    ],
    previewTitle: "Kardeş Kıskançlığı Neden Ortaya Çıkar?",
    previewArticle: (
      <body>
Kıskançlık, en basit anlamda kişinin sahip olduğu şeyi kaybetme korkusundan ortaya çıkar. Kardeş kıskançlığı hisseden çocuk için bu korku, hayatındaki en değerli şey olan anne ve baba sevgisini kaybetme korkusudur. Bu da korkuların en büyüğüdür. Bu durumda şu soruyu sormak gerekir, bir çocuk neden anne ve babasının sevgisini kaybetmekten korkar? Başka bir kardeş çocukta neden korku yaratır?
Çocuk, ailesinin sevgisinin “sınırlı” olduğunu düşünürse, sevgiyi kaybetmekten korkmaya başlar. Çocuk, “Ailemin sevgisi sınırlı olduğu için, en fazlasını kardeşim alabilir. Dolayısıyla bana sevgi kalmaz. Sevgiyi alamayınca da yalnız kalırım.” diye düşünür. Peki çocuk neden sevginin sınırlı olduğunu düşünür?
Bunun iki temel nedeni var: koşullu sevgi ve güvensizlik. Bir evde ne kadar koşul varsa çocuk sevginin o kadar sınırlı ve yetersiz olduğunu düşünür. Koşullu sevgi; çocuğun yaptığı davranışlar karşısında verilen sevgidir. Örneğin, evde sürekli övgü, yargı, ödül veya ceza varsa, çocuk sevgiyi davranışlara bağlı olarak kafasında kodlar. Çocuk der ki ‘Annem/Babam onun istediği bir davranışı yapınca beni seviyor (ödül ve övgü), yapmayınca sevmiyor (yargı ve ceza)’. Davranışlara bağlı sevgi de sayılabilir olduğu için çocuk sevginin sayılabilir ve dolayısıyla sınırlı olduğunu düşünür. Çocuk; “Kardeşim beş övgü (yapay sevgi), ben iki övgü aldım. Öyleyse kardeşimi daha çok seviyor.” diye düşünür. Kıskançlık ise bu yarışın sonucu olarak ortaya çıkar.
Ama evde koşulsuz sevgi varsa (koşulsuz sevgi videolarımızı izleyin) çocuk der ki “Ailemin sevgisi sınırsız. Ailem kardeşimi sevse de bana karşı sevgisi azalmaz. Her kardeşi aynı anda sevebilir.” Bu durumda kıskançlık davranışları gösterme ihtiyacı duymaz. İkinci neden ise güvensizliktir. Çocukla güvenli bağ kurmak demek, sadece onun fiziksel ihtiyaçlarını karşılamak demek değildir. Güvenli bağ kurmanın temelinde koşulsuz sevgi vardır. Evde koşulsuz sevgi varsa çocuk davranışlarıyla değil, kimliği ile sevildiğini düşünür. Bu durumda da sevgide tutarlılık ve süreklilik vardır. Ailenin sevgisi davranışlara göre değişmez. Çocuk der ki “Ailem beni ben olduğum için zaten seviyor. Ben hata da yapsam ailemin sevgisi azalmaz veya başka birisine kaymaz” Ama çocukla aile arasında güvene dayalı bir ilişki yoksa, çocuk ailesinin sevgisini kaybedeceğini düşünür. Evde kardeş varsa, bu korkuyu kıskançlık olarak gösterir. O zaman kardeş kıskançlığını yenmek için ilk olarak aile çocuğunla güvenli bağ (güvenli bağ videolarımızı izleyin) kurmalı ve koşulsuz sevgi sunmalıdır. Kardeş kıskançlığı sorununu çözmek için ‘kardeş kıskançlığı’ eğitimimizdeki videoları izleyin.
      </body>
    ),
  };
  