/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import AOS from "aos";
import images from "../../img/index";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import emailjs from "@emailjs/browser";
import Loading from "../../components/Loading/Loading";
import { faq } from "../../data/faq.data";

const Contact = () => {
  const [mailResponse, setMailResponse] = useState("");
  const [sendButtonDisable, setSendButtonDisable] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  let handleEmail = async (e) => {
    e.preventDefault();

    if (e.target.user_name.value.length < 5) {
      setMailResponse("İsim soyisim çok kısa");
      return;
    }

    if (!e.target.user_mail.value.includes("@")) {
      setMailResponse("Geçersiz mail adresi");
      return;
    }

    if (e.target.user_phone.value.length < 9) {
      setMailResponse("Geçersiz telefon numarası");
      return;
    }

    if (e.target.message.value.length < 10) {
      setMailResponse("Mesajınız çok kısa");
      return;
    }
    setSendButtonDisable(true);
    setMailResponse("Gönderiliyor...");

    await emailjs
      .sendForm(
        "parentwiser_service",
        "parentwiser_support",
        e.target,
        "user_Y2J3LhR2MYqfH6Z4JVJgK"
      )
      .then(
        (result) => {
          console.log(result);
          if ((result.status = 200)) {
            setMailResponse("Mail Gönderildi.");
          } else {
            setMailResponse("Mail Gönderilirken Bir HATA Meydana Geldi.");
          }
        },
        (error) => {
          setMailResponse("Mail Gönderilirken Bir HATA Meydana Geldi.");
          console.log(error);
        }
      );


    setSendButtonDisable(false);
    e.target.reset();
  };

  return (
    <div className="page">
      <div className="contact">
        <div className="contact__center center">
          <div className="contact__bg">
            <div className="contact__preview">
              <img
                srcSet={`${images.staircase2x} 2x`}
                src={images.staircase}
                alt=""
              />
            </div>
            <div
              className="contact__preview"
              data-aos="animation-translate-up"
              data-aos-duration="1000"
            >
              <img
                srcSet={`${images.straightPhone2x} 2x`}
                src={images.straightPhone}
                alt=""
              />
            </div>
            <div
              className="contact__preview"
              data-aos="animation-translate-down"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <img
                srcSet={`${images.reverseMessageBox2x} 2x`}
                src={images.reverseMessageBox}
                alt=""
              />
            </div>
            <div
              className="contact__preview"
              data-aos="animation-translate-down"
              data-aos-duration="1000"
              data-aos-delay="800"
            >
              <img
                srcSet={`${images.smallMessageBox2x} 2x`}
                src={images.smallMessageBox}
                alt=""
              />
            </div>
            <div className="contact__preview">
              <img srcSet={`${images.arch2x} 2x`} src={images.arch} alt="" />
            </div>
          </div>
          <div className="contact__wrap">
            <h1
              className="contact__title h2"
              data-aos="animation-scale-top"
              data-aos-duration="600"
            >
              Bize Ulaşın
            </h1>
            <div className="contact__info">Sormak İstediğiniz Sorular İçin</div>
            {/* CONTACT FORM */}
            <form className="contact__form" onSubmit={handleEmail}>
              <div className="field">
                <div className="field__label">İsim Soyisim</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    placeholder="İsim Soyisim"
                    name="user_name"
                  />
                </div>
              </div>
              <div className="field">
                <div className="field__label">Mail Adresiniz:</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="email"
                    placeholder="E-Posta"
                    name="user_mail"
                  />
                </div>
              </div>
              <div className="field">
                <div className="field__label">Telefon Numaranız:</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="number"
                    placeholder="Telefon Numaranız"
                    name="user_phone"
                  />
                </div>
              </div>
              <div className="field">
                <div className="field__label">
                  Destek Almak İstediğiniz Konu:
                </div>
                <div className="field__wrap">
                  <textarea
                    className="field__textarea"
                    placeholder="Mesaj"
                    name="message"
                  ></textarea>
                </div>
              </div>
              <input
                className="main__btn btn btn_border"
                style={{ cursor: "pointer" }}
                type="submit"
                value="Gönder"
                disable={sendButtonDisable}
              ></input>
            </form>
            <div className="download__body">
              <div className="review__center" style={{ marginTop: 40 }}>
                <div class="stage" style={{ fontSize: 20 }}>
                  {mailResponse}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="share">
        <div className="share__center center">
          <div className="share__container">
            <div className="share__bg"></div>
            <div className="share__row">
              <div className="share__col">
                <a
                  href="mailto:destek@parentwiser.com.tr?subject=SendMail&body=Description"
                  style={{ color: "#FFFFFF" }}
                >
                  <div
                    className="share__preview"
                    data-aos="animation-scale-top"
                    data-aos-duration="400"
                  >
                    <img
                      srcSet={`${images.mailPng2x} 2x`}
                      src={images.mailPng}
                      alt=""
                    />
                  </div>
                  <h4
                    className="share__title h4"
                    data-aos="animation-scale-top"
                    data-aos-duration="200"
                    data-aos-delay="400"
                  >
                    Destek E-Posta
                  </h4>
                  <span style={{ color: "#FFFFFF" }}>
                    destek@parentwiser.com.tr
                  </span>
                </a>
              </div>
              <div className="share__col">
                <a href="tel:+90-850-305-71-29" style={{ color: "#FFFFFF" }}>
                  <div
                    className="share__preview"
                    data-aos="animation-scale-top"
                    data-aos-duration="400"
                    data-aos-delay="300"
                  >
                    <img
                      srcSet={`${images.phonePng2x} 2x`}
                      src={images.phonePng}
                      alt=""
                    />
                  </div>
                  <h4
                    className="share__title h4"
                    data-aos="animation-scale-top"
                    data-aos-duration="200"
                    data-aos-delay="400"
                  >
                    Destek Hattı
                  </h4>
                  <span style={{ color: "#FFFFFF" }}> +90 850 305 71 29</span>{" "}
                  <br />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="questions">
        <div className="questions__center center">
          <div className="questions__preview">
            <img srcset={`${images.book2x} 2x`} src={images.book} alt="" />
          </div>
          <h2
            className="questions__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Sıkça Sorulan Sorular
          </h2>
          <Accordion className="questions__list">
            {faq.map((element) => {
              return (
                <AccordionItem className="questions__item">
                  <AccordionItemButton className="questions__head">
                    {element.question}
                  </AccordionItemButton>
                  <AccordionItemPanel>
                    <p>{element.answer}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Contact;
