import React, { useEffect, useState } from "react";
import QuestionCard from "./components/questionCard";
import AOS from "aos";
import Loading from "../../components/Loading/Loading";
import "../../components/GlobalCss/globalStyle.css";
import parser from "html-react-parser";
import { mukemmeliyetcilik, tutarliEbeveyn } from "../../data/survey.data";

const Survey = () => {

  const [currentSurvey, setCurrentSurvey] = useState({});
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [refreshCard, setRefreshCard] = useState(false);
  const [resultText, setResultText] = useState("");
  const [resultOutput, setResultOutput] = useState("");

  useEffect(() => {
    AOS.init();

    const queryParams = new URLSearchParams(window.location.search);
    const surveyId = queryParams.get("id");

    switch (surveyId) {
      case "101":
        setCurrentSurvey(tutarliEbeveyn);
        break;
      case "102":
        setCurrentSurvey(mukemmeliyetcilik)
        break;
      default:
        setCurrentSurvey(tutarliEbeveyn)
        break;
    }

  }, []);

  let handleSurveyResult = () => {
    if (surveyAnswers.length < currentSurvey.questions.length) {
      setResultText("Tüm Sorulara Cevap Vermelisiniz.");
    } else {
      var totalPoint = 0;

      surveyAnswers.forEach((element) => {
        totalPoint = totalPoint + element.point;
      });

      currentSurvey.answers.forEach((element) => {
        if (element.minPoint <= totalPoint && totalPoint <= element.maxPoint) {
          setResultText(element.resultText);
          setResultOutput(element.resultOutput);
        }
      });
    }
  };

  let setAnswer = (questionNumber, answerPoint) => {
    if (surveyAnswers[questionNumber - 1] !== undefined) {
      surveyAnswers[questionNumber - 1] = {
        questionNum: questionNumber,
        point: answerPoint,
      };
    } else {
      setSurveyAnswers([
        ...surveyAnswers,
        { questionNum: questionNumber, point: answerPoint },
      ]);
    }
    setRefreshCard(!refreshCard);
  };

  return (
    <div className="page">
      <div class="download">
        <div class="download__center center">
          <div class="stage">Parentwiser</div>
          <div class="download__body">
            <div className="offer__container h4">
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
                <span style={{ color: "#ffffff" }}>{currentSurvey.title}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {currentSurvey?.questions?.map((element) => {
          return (
            <QuestionCard
              questionNumber={element.questionNumber}
              totalQuestionNumber={
                currentSurvey.questions.length
              }
              questionText={element.questionText}
              answerOptions={element.answerOptions}
              setAnswer={setAnswer}
              surveyAnswers={surveyAnswers}
              refreshCard={refreshCard}
            />
          );
        })}
        <button
          onClick={handleSurveyResult}
          className="pricing__btn btn btn_orange"
        >
          Sonucu Gör
        </button>

        <div className="download__body">
          <div className="review__center" style={{ marginTop: 40 }}>
            <div class="stage" style={{ fontSize: 20 }}>
              {resultText}
            </div>
          </div>
        </div>
      </div>

      <div className="productivity">
        {resultOutput == "" ? null : (
          <div className="productivity__center center">
            <div className="productivity__container">
              <div className="productivity__item">
                <div className="productivity__details">
                  {parser(resultOutput)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survey;
