import React, { useEffect } from "react";
import "./questionCard.css"

const QuestionCard = (props) => {

  useEffect(() => {
    
  }, [props.surveyAnswers,props.refreshCard]);
  

  return (
    <div className="cardBg">
      <div className="question-section">
        <div className="question-count">
          <span>Soru {props.questionNumber}</span>
        </div>
        <div className="question-text">{props.questionText}</div>
      </div>
      <div className="answer-section">
        {props.answerOptions.map(
          (answerOption) =>
            <button onClick={()=>{props.setAnswer(props.questionNumber,answerOption.point)}} 
              className={
                props.surveyAnswers.some(q => (q.questionNum === props.questionNumber && q.point === answerOption.point)) ? (
                  "selectedCardButton"
                ):(
                  "cardButton"
                )
              }
              >
              {answerOption.answerText}
            </button>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
