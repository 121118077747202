import React, { useEffect, useState } from "react";
import AOS from "aos";
import { earningsList,parentSchoolTopics,sampleParentSchoolVideos } from "../../data/parent.school.page";

const ParentSchool = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  function splitArrayIntoTwoRow(arr, len) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  return (
        <div className="page">
          <div className="download">
            <div className="download__center center">
              <div className="stage">Parentwiser</div>
              <div className="download__body">
                <div className="offer__container h4" style={{color:"white"}}>
                  <h3 data-aos="animation-scale-top" data-aos-duration="600">
                    Anne Baba Okulu
                  </h3>
                </div>
                <div className="review__center" style={{ marginTop: 40 }}>
                  <div className="download__info">
                    Çocuğumuzla yaşadığımız sorunları çocuğumuzla ilişkimizi bozmadan çözmek önemli ama asıl önemli olan sorunların çıkmasını engellemek. Bunun için de ebeveynlerin çocuğu ile güçlü bir ilişki kurması gerekir. Örneğin çocuğumuzu dinlersek, çocuğumuz kendini duyurmak için ‘ağlama’ gereği duymayacak veya çocuğumuzun öfkesini sağlıklı şekilde ifade etmesini öğretirsek, ‘alt ıslatma’ sorunu çıkmayacak. Sorun temelli eğitimlere ek olarak, Anne Baba Okulunda ebeveynlerin daha yetkin olması için birçok modül bulunmaktadır.
                  </div>
                  <h4
                    class="review__title h4"
                    data-aos="animation-scale-top"
                    data-aos-duration="600"
                  >
                Ebeveynler yıl boyunca alacağı dersler ile şu kazanımlara sahip olacaktır;
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {/* Preview */}
          <div className="productivity">
            <div className="productivity__center center">
              <div className="productivity__container">
                <div className="productivity__bg"></div>
                <div className="productivity__row">
                  <div className="productivity__list">
                    {splitArrayIntoTwoRow(
                      earningsList,
                      (earningsList.length +
                        1) /
                        2
                    )[0].map((element) => {
                      return (
                        <div className="productivity__item">
                        <div className="productivity__details">
                          <div
                            className="productivity__category"
                            data-aos="animation-scale-top"
                            data-aos-duration="200"
                          >
                            {element}
                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                  <div className="productivity__list">
                    {splitArrayIntoTwoRow(
                      earningsList,
                      (earningsList.length +
                        1) /
                        2
                    )[1].map((element) => {
                      return (
                        <div className="productivity__item">
                        <div className="productivity__details">
                          <div
                            className="productivity__category"
                            data-aos="animation-scale-top"
                            data-aos-duration="200"
                          >
                            {element}
                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="download">
            <div className="download__center center">
              <div className="stage">Parentwiser</div>
              <h1
                className="download__title h3"
                data-aos="animation-scale-top"
                data-aos-duration="600"
              >
                Anne Baba Okulunun İçerisindeki Konular
              </h1>
              <div className="download__info">
              Aşağıdaki konulara sürekli yeni konular eklenecektir. Anne Baba Okulunda her hafta bir konu işlenecektir.
              </div>
              <div className="download__body">
                <div className="download__list">
                  {parentSchoolTopics.map(
                    (element) => {
                      return (
                        <a className="download__item">
                          <div className="download__category">
                            {element.title}
                          </div>
                          <div className="download__text">
                            {element.subtitle}
                          </div>
                        </a>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Sample Video Trainings */}
          <div className="pricing">
            <div className="pricing__center center">
              <h1
                className="pricing__title h2"
                data-aos="animation-scale-top"
                data-aos-duration="600"
              >
                Anne Baba Okulu Video Örnekleri
              </h1>
              <div className="pricing__info">
                
              </div>
              <div className="pricing__list">
                {sampleParentSchoolVideos.map(
                  (element) => {
                    return (
                      <div className="pricing__item">
                        <div className="pricing__head">
                          <div
                            className="pricing__subtitle"
                            data-aos="animation-scale-top"
                            data-aos-duration="600"
                            data-aos-delay="200"
                          >
                            {element.title}
                          </div>
                          <div className="pricing__text">
                            {element.subtitle}
                          </div>
                          <button
                            onClick={() =>
                              (window.location.href = `/videoArticle?id=${element.id}`)
                            }
                            className="pricing__btn btn btn_orange"
                          >
                            İzle
                          </button>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
  );
};

export default ParentSchool;
