import parser from "html-react-parser";
import { useEffect } from "react";
import AOS from "aos";

const Kvkk = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="page">
      <div className="productivity">
        <div className="productivity__center center">
          <div
            className="offer__container h4"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
              Kişisel Verilerin Korunması Kanunu
            </h3>
          </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(`
              Kanun Numarası 6698 <br/>

              Kabul Tarihi : 24/3/2016<br/>
              
              Yayımlandığı R. Gazete : Tarih: 7/4/2016 Sayı: 29677 Yayımlandığı Düstur : Tertip: 5 Cilt: 57<br/>
              
              
              
              BİRİNCİ BÖLÜM<br/>
              
              Amaç, Kapsam ve Tanımlar<br/>
              
              Amaç<br/>
              
              MADDE 1- (1) Bu Kanunun amacı, kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile uyacakları usul ve esasları düzenlemektir.
              
              Kapsam<br/>
              
              MADDE 2- (1) Bu Kanun hükümleri, kişisel verileri işlenen gerçek kişiler ile bu verileri tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işleyen gerçek ve tüzel kişiler hakkında uygulanır.
              
              Tanımlar<br/>
              
              MADDE 3- (1) Bu Kanunun uygulanmasında;
              
              Açık rıza: Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı,
              
              Anonim hâle getirme: Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesini,
              
              Başkan: Kişisel Verileri Koruma Kurumu Başkanını, ç) İlgili kişi: Kişisel verisi işlenen gerçek kişiyi,
              
              Kişisel veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
              
              Kişisel verilerin işlenmesi: Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,
              
              Kurul: Kişisel Verileri Koruma Kurulunu,
              
              Kurum: Kişisel Verileri Koruma Kurumunu,
              
              ğ) Veri işleyen: Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi,
              
              
              
              Veri kayıt sistemi: Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini,
              
              ı) Veri sorumlusu: Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi, ifade eder.
              
              
              
              İKİNCİ BÖLÜM
              
              Kişisel Verilerin İşlenmesi
              
              Genel ilkeler
              
              MADDE 4- (1) Kişisel veriler, ancak bu Kanunda ve diğer kanunlarda öngörülen usul ve esaslara uygun olarak işlenebilir.
              
              (2) Kişisel verilerin işlenmesinde aşağıdaki ilkelere uyulması zorunludur:
              
              Hukuka ve dürüstlük kurallarına uygun olma.
              
              Doğru ve gerektiğinde güncel olma.
              
              Belirli, açık ve meşru amaçlar için işlenme.
              
              ç) İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma.
              
              İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza
              
              edilme.
              
              Kişisel verilerin işlenme şartları
              
              MADDE 5- (1) Kişisel veriler ilgili kişinin açık rızası olmaksızın işlenemez.
              
              Aşağıdaki şartlardan birinin varlığı hâlinde, ilgili kişinin açık rızası aranmaksızın
              
              kişisel verilerinin işlenmesi mümkündür:
              
              Kanunlarda açıkça öngörülmesi.
              
              Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.
              
              Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.
              
              ç) Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.
              
              İlgili kişinin kendisi tarafından alenileştirilmiş olması.
              
              Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.
              
              İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.
              
              
              
              Özel nitelikli kişisel verilerin işlenme şartları
              
              MADDE 6- (1) Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.
              
              Özel nitelikli kişisel verilerin, ilgilinin açık rızası olmaksızın işlenmesi yasaktır.
              
              
              
              Birinci fıkrada sayılan sağlık ve cinsel hayat dışındaki kişisel veriler, kanunlarda öngörülen hâllerde ilgili kişinin açık rızası aranmaksızın işlenebilir. Sağlık ve cinsel hayata ilişkin kişisel veriler ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası aranmaksızın işlenebilir.
              
              Özel nitelikli kişisel verilerin işlenmesinde, ayrıca Kurul tarafından belirlenen yeterli önlemlerin alınması şarttır.
              
              
              
              Kişisel verilerin silinmesi, yok edilmesi veya anonim hâle getirilmesi
              
              MADDE 7- (1) Bu Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel veriler resen veya ilgili kişinin talebi üzerine veri sorumlusu tarafından silinir, yok edilir veya anonim hâle getirilir.
              
              Kişisel verilerin silinmesi, yok edilmesi veya anonim hâle getirilmesine ilişkin diğer kanunlarda yer alan hükümler saklıdır.
              
              Kişisel verilerin silinmesine, yok edilmesine veya anonim hâle getirilmesine ilişkin usul ve esaslar yönetmelikle düzenlenir.
              
              
              
              Kişisel verilerin aktarılması
              
              MADDE 8- (1) Kişisel veriler, ilgili kişinin açık rızası olmaksızın aktarılamaz.
              
              Kişisel veriler;
              
              5 inci maddenin ikinci fıkrasında,
              
              Yeterli önlemler alınmak kaydıyla, 6. maddenin üçüncü fıkrasında,
              
              belirtilen şartlardan birinin bulunması hâlinde, ilgili kişinin açık rızası aranmaksızın aktarılabilir.
              
              Kişisel verilerin aktarılmasına ilişkin diğer kanunlarda yer alan hükümler saklıdır.
              
              
              
              Kişisel verilerin yurt dışına aktarılması
              
              MADDE 9- (1) Kişisel veriler, ilgili kişinin açık rızası olmaksızın yurt dışına aktarılamaz.
              
              Kişisel veriler, 5. maddenin ikinci fıkrası ile 6. maddenin üçüncü fıkrasında belirtilen şartlardan birinin varlığı ve kişisel verinin aktarılacağı yabancı ülkede;
              
              Yeterli korumanın bulunması,
              
              Yeterli korumanın bulunmaması durumunda Türkiye’deki ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun izninin bulunması,
              
              kaydıyla ilgili kişinin açık rızası aranmaksızın yurt dışına aktarılabilir.
              
              Yeterli korumanın bulunduğu ülkeler Kurulca belirlenerek ilan edilir.
              
              
              
              Kurul yabancı ülkede yeterli koruma bulunup bulunmadığına ve ikinci fıkranın (b) bendi uyarınca izin verilip verilmeyeceğine;
              
              Türkiye’nin taraf olduğu uluslararası sözleşmeleri,
              
              Kişisel veri talep eden ülke ile Türkiye arasında veri aktarımına ilişkin karşılıklılık durumunu,
              
              Her somut kişisel veri aktarımına ilişkin olarak, kişisel verinin niteliği ile işlenme amaç ve süresini,
              
              ç) Kişisel verinin aktarılacağı ülkenin konuyla ilgili mevzuatı ve uygulamasını,
              
              Kişisel verinin aktarılacağı ülkede bulunan veri sorumlusu tarafından taahhüt edilen önlemleri değerlendirmek ve ihtiyaç duyması hâlinde, ilgili kurum ve kuruluşların görüşünü de almak suretiyle karar verir.
              
              Kişisel veriler, uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye’nin veya ilgili kişinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, ancak ilgili kamu kurum veya kuruluşunun görüşü alınarak Kurulun izniyle yurt dışına aktarılabilir.
              
              Kişisel verilerin yurt dışına aktarılmasına ilişkin diğer kanunlarda yer alan hükümler saklıdır.
              
              
              
              ÜÇÜNCÜ BÖLÜM
              
              Haklar ve Yükümlülükler 
              
              
              
              Veri sorumlusunun aydınlatma yükümlülüğü
              
              MADDE 10- (1) Kişisel verilerin elde edilmesi sırasında veri sorumlusu veya yetkilendirdiği kişi, ilgili kişilere;
              
              Veri sorumlusunun ve varsa temsilcisinin kimliği,
              
              Kişisel verilerin hangi amaçla işleneceği,
              
              İşlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, ç) Kişisel veri toplamanın yöntemi ve hukuki sebebi,
              
              11. maddede sayılan diğer hakları konusunda bilgi vermekle yükümlüdür. 
              
              
              
              İlgili kişinin hakları
              
              MADDE 11- (1) Herkes, veri sorumlusuna başvurarak kendisiyle ilgili;
              
              Kişisel veri işlenip işlenmediğini öğrenme,
              
              Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              
              Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
              
              ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
              
              Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
              
              isteme,
              
              
              
              7. maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,
              
              (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
              
              ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.
              
              
              
              Veri güvenliğine ilişkin yükümlülükler MADDE 12- (1) Veri sorumlusu;
              
              Kişisel verilerin hukuka aykırı olarak işlenmesini önlemek,
              
              Kişisel verilere hukuka aykırı olarak erişilmesini önlemek,
              
              Kişisel verilerin muhafazasını sağlamak,
              
              amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almak zorundadır.
              
              Veri sorumlusu, kişisel verilerin kendi adına başka bir gerçek veya tüzel kişi tarafından işlenmesi hâlinde, birinci fıkrada belirtilen tedbirlerin alınması hususunda bu kişilerle birlikte müştereken sorumludur.
              
              Veri sorumlusu, kendi kurum veya kuruluşunda, bu kanun hükümlerinin uygulanmasını sağlamak amacıyla gerekli denetimleri yapmak veya yaptırmak zorundadır.
              
              Veri sorumluları ile veri işleyen kişiler, öğrendikleri kişisel verileri bu kanun hükümlerine aykırı olarak başkasına açıklayamaz ve işleme amacı dışında kullanamazlar. Bu yükümlülük görevden ayrılmalarından sonra da devam eder.
              
              İşlenen kişisel verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi hâlinde, veri sorumlusu bu durumu en kısa sürede ilgilisine ve kurula bildirir. Kurul, gerekmesi hâlinde bu durumu, kendi internet sitesinde ya da uygun göreceği başka bir yöntemle ilan edebilir.
              
              
              
              DÖRDÜNCÜ BÖLÜM
              
              Başvuru, Şikâyet ve Veri Sorumluları Sicili Veri sorumlusuna başvuru
              
              MADDE 13- (1) İlgili kişi, bu kanunun uygulanmasıyla ilgili taleplerini yazılı olarak veya Kurulun belirleyeceği diğer yöntemlerle veri sorumlusuna iletir.
              
              Veri sorumlusu başvuruda yer alan talepleri, talebin niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandırır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Kurulca belirlenen tarifedeki ücret alınabilir.
              
              Veri sorumlusu talebi kabul eder veya gerekçesini açıklayarak reddeder ve cevabını ilgili kişiye yazılı olarak veya elektronik ortamda bildirir. Başvuruda yer alan talebin kabul edilmesi hâlinde veri sorumlusunca gereği yerine getirilir. Başvurunun veri sorumlusunun hatasından kaynaklanması hâlinde alınan ücret ilgiliye iade edilir.
              
              
              
              Kurula şikâyet
              
              MADDE 14- (1) Başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya süresinde başvuruya cevap verilmemesi hâllerinde; ilgili kişi, veri sorumlusunun cevabını öğrendiği tarihten itibaren otuz ve herhâlde başvuru tarihinden itibaren altmış gün içinde Kurula şikâyette bulunabilir.
              
              13. madde uyarınca başvuru yolu tüketilmeden şikâyet yoluna başvurulamaz.
              
              Kişilik hakları ihlal edilenlerin, genel hükümlere göre tazminat hakkı saklıdır.
              
              
              
              Şikâyet üzerine veya resen incelemenin usul ve esasları
              
              MADDE 15- (1) Kurul, şikâyet üzerine veya ihlal iddiasını öğrenmesi durumunda resen, görev alanına giren konularda gerekli incelemeyi yapar.
              
              1/11/1984 tarihli ve 3071 sayılı Dilekçe Hakkının Kullanılmasına Dair Kanunun 6. maddesinde belirtilen şartları taşımayan ihbar veya şikâyetler incelemeye alınmaz.
              
              Devlet sırrı niteliğindeki bilgi ve belgeler hariç; veri sorumlusu, Kurulun, inceleme konusuyla ilgili istemiş olduğu bilgi ve belgeleri on beş gün içinde göndermek ve gerektiğinde yerinde inceleme yapılmasına imkân sağlamak zorundadır.
              
              Şikâyet üzerine kurul, talebi inceleyerek ilgililere bir cevap verir. Şikâyet tarihinden itibaren altmış gün içinde cevap verilmezse talep reddedilmiş sayılır.
              
              Şikâyet üzerine veya resen yapılan inceleme sonucunda, ihlalin varlığının anlaşılması hâlinde kurul, tespit ettiği hukuka aykırılıkların veri sorumlusu tarafından giderilmesine karar vererek ilgililere tebliğ eder. Bu karar, tebliğden itibaren gecikmeksizin ve en geç otuz gün içinde yerine getirilir.
              
              Şikâyet üzerine veya resen yapılan inceleme sonucunda, ihlalin yaygın olduğunun tespit edilmesi hâlinde kurul, bu konuda ilke kararı alır ve bu kararı yayımlar. Kurul, ilke kararı almadan önce ihtiyaç duyması hâlinde, ilgili kurum ve kuruluşların görüşlerini de alabilir.
              
              Kurul, telafisi güç veya imkânsız zararların doğması ve açıkça hukuka aykırılık olması hâlinde, veri işlenmesinin veya verinin yurt dışına aktarılmasının durdurulmasına karar verebilir.
              
              
              
              Veri Sorumluları Sicili
              
              MADDE 16- (1) Kurulun gözetiminde, başkanlık tarafından kamuya açık olarak veri Sorumluları Sicili tutulur.
              
              Kişisel verileri işleyen gerçek ve tüzel kişiler, veri işlemeye başlamadan önce Veri Sorumluları Siciline kaydolmak zorundadır. Ancak, işlenen kişisel verinin niteliği, sayısı, veri işlemenin kanundan kaynaklanması veya üçüncü kişilere aktarılma durumu gibi Kurulca belirlenecek objektif kriterler göz önüne alınmak suretiyle, Kurul tarafından, Veri Sorumluları Siciline kayıt zorunluluğuna istisna getirilebilir.
              
              Veri Sorumluları Siciline kayıt başvurusu aşağıdaki hususları içeren bir bildirimle
              
              yapılır:
              
              Veri sorumlusu ve varsa temsilcisinin kimlik ve adres bilgileri.
              
              Kişisel verilerin hangi amaçla işleneceği.
              
              
              
              Veri konusu kişi grubu ve grupları ile bu kişilere ait veri kategorileri hakkındaki açıklamalar.
              
              ç) Kişisel verilerin aktarılabileceği alıcı veya alıcı grupları.
              
              Yabancı ülkelere aktarımı öngörülen kişisel veriler.
              
              Kişisel veri güvenliğine ilişkin alınan tedbirler.
              
              Kişisel verilerin işlendikleri amaç için gerekli olan azami süre.
              
              Üçüncü fıkra uyarınca verilen bilgilerde meydana gelen değişiklikler derhâl Başkanlığa bildirilir.
              
              Veri Sorumluları Siciline ilişkin diğer usul ve esaslar yönetmelikle düzenlenir.
              
              
              
              BEŞİNCİ BÖLÜM
              
              Suçlar ve Kabahatler
              
              Suçlar
              
              MADDE 17- (1) Kişisel verilere ilişkin suçlar bakımından 26/9/2004 tarihli ve 5237 sayılı Türk Ceza Kanununun 135 ila 140. madde hükümleri uygulanır.
              
              (2) Bu Kanunun 7. maddesi hükmüne aykırı olarak; kişisel verileri silmeyen veya anonim hâle getirmeyenler 5237 sayılı Kanunun 138 inci maddesine göre cezalandırılır.
              
              
              
              Kabahatler
              
              MADDE 18- (1) Bu Kanunun;
              
              10. maddesinde öngörülen aydınlatma yükümlülüğünü yerine getirmeyenler hakkında 5.000 Türk lirasından 100.000 Türk lirasına kadar,
              
              12. maddesinde öngörülen veri güvenliğine ilişkin yükümlülükleri yerine getirmeyenler hakkında 15.000 Türk lirasından 1.000.000 Türk lirasına kadar,
              
              15. maddesi uyarınca Kurul tarafından verilen kararları yerine getirmeyenler hakkında 25.000 Türk lirasından 1.000.000 Türk lirasına kadar,
              
              ç) 16. maddesinde öngörülen Veri Sorumluları Siciline kayıt ve bildirim yükümlülüğüne aykırı hareket edenler hakkında 20.000 Türk lirasından 1.000.000 Türk lirasına kadar,
              
              idari para cezası verilir.
              
              Bu maddede öngörülen idari para cezaları veri sorumlusu olan gerçek kişiler ile özel hukuk tüzel kişileri hakkında uygulanır.
              
              Birinci fıkrada sayılan eylemlerin kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşları bünyesinde işlenmesi hâlinde, Kurulun yapacağı bildirim üzerine, ilgili kamu kurum ve kuruluşunda görev yapan memurlar ve diğer kamu görevlileri ile kamu kurumu niteliğindeki meslek kuruluşlarında görev yapanlar hakkında disiplin hükümlerine göre işlem yapılır ve sonucu Kurula bildirilir.
              
              
              
              ALTINCI BÖLÜM
              
              Kişisel Verileri Koruma Kurumu ve Teşkilat Kişisel Verileri Koruma Kurumu
              
              
              
              MADDE 19- (1) Bu Kanunla verilen görevleri yerine getirmek üzere, idari ve mali özerkliğe sahip ve kamu tüzel kişiliğini haiz Kişisel Verileri Koruma Kurumu kurulmuştur.
              
              Kurum Cumhurbaşkanının görevlendireceği bakan ile ilişkilidir. (1)
              
              Kurumun merkezi Ankara’dadır.
              
              Kurum, Kurul ve Başkanlıktan oluşur. Kurumun karar organı Kuruldur.
              
              
              
              Kurumun görevleri
              
              MADDE 20- (1) Kurumun görevleri şunlardır:
              
              Görev alanı itibarıyla, uygulamaları ve mevzuattaki gelişmeleri takip etmek, değerlendirme ve önerilerde bulunmak, araştırma ve incelemeler yapmak veya yaptırmak.
              
              İhtiyaç duyulması hâlinde, görev alanına giren konularda kamu kurum ve kuruluşları, sivil toplum kuruluşları, meslek örgütleri veya üniversitelerle iş birliği yapmak.
              
              Kişisel verilerle ilgili uluslararası gelişmeleri izlemek ve değerlendirmek, görev alanına giren konularda uluslararası kuruluşlarla iş birliği yapmak, toplantılara katılmak.
              
              ç) Yıllık faaliyet raporunu Cumhurbaşkanlığına, Türkiye Büyük Millet Meclisi İnsan Haklarını İnceleme Komisyonuna (…) (2) sunmak. (2)
              
              Kanunlarla verilen diğer görevleri yerine getirmek.
              
              
              
              Kişisel Verileri Koruma Kurulu (3)
              
              MADDE 21- (1) Kurul, bu Kanunla ve diğer mevzuatla verilen görev ve yetkilerini kendi sorumluluğu altında, bağımsız olarak yerine getirir ve kullanır. Görev alanına giren konularla ilgili olarak hiçbir organ, makam, merci veya kişi, Kurula emir ve talimat veremez, tavsiye veya telkinde bulunamaz.
              
              Kurul, dokuz üyeden oluşur. Kurulun beş üyesi Türkiye Büyük Millet Meclisi, dört üyesi Cumhurbaşkanı tarafından seçilir. (3)
              
              Kurula üye olabilmek için aşağıdaki şartlar aranır:
              
              Kurumun görev alanındaki konularda bilgi ve deneyim sahibi olmak.
              
              14/7/1965 tarihli ve 657 sayılı Devlet Memurları Kanununun 48. maddesinin birinci fıkrasının
              
              (A) bendinin (1), (4), (5), (6) ve (7) numaralı alt bentlerinde belirtilen nitelikleri taşımak.
              
              Herhangi bir siyasi parti üyesi olmamak.
              
              ç) En az dört yıllık lisans düzeyinde yükseköğrenim görmüş olmak. 
              
              d) (Mülga: 2/7/2018-KHK-703/163 Md.)
              
              –––––––––––––––––
              
              2/7/2018 tarihli ve 703 sayılı KHK’nin 163. maddesiyle, bu fıkrada yer alan “Başbakanlıkla” ibaresi “Cumhurbaşkanının görevlendireceği bakan ile” şeklinde değiştirilmiştir.
              
              2/7/2018 tarihli ve 703 sayılı KHK’nin 163. maddesiyle, bu bentte yer alan “ve Başbakanlığa” ibaresi yürürlükten kaldırılmıştır.
              
              2/7/2018 tarihli ve 703 sayılı KHK’nin 163. maddesiyle, bu maddenin ikinci fıkrasında yer alan “iki üyesi Cumhurbaşkanı, iki üyesi Bakanlar Kurulu” ibaresi “dört üyesi Cumhurbaşkanı” şeklinde değiştirilmiştir.
              
              
              
              (4) (Mülga: 2/7/2018-KHK-703/163 Md.)
              
              Türkiye Büyük Millet Meclisi, Kurula üye seçimini aşağıdaki usulle yapar:
              
              Seçim için, siyasi parti gruplarının üye sayısı oranında belirlenecek üye sayısının ikişer katı aday gösterilir ve Kurul üyeleri bu adaylar arasından her siyasi parti grubuna düşen üye sayısı esas alınmak suretiyle Türkiye Büyük Millet Meclisi Genel Kurulunca seçilir. Ancak, siyasi parti gruplarında, Türkiye Büyük Millet Meclisinde yapılacak seçimlerde kime oy kullanılacağına dair görüşme yapılamaz ve karar alınamaz.
              
              Kurul üyelerinin seçimi, adayların belirlenerek ilanından sonra on gün içinde yapılır. Siyasi parti grupları tarafından gösterilen adaylar için ayrı ayrı listeler hâlinde birleşik oy pusulası düzenlenir. Adayların adlarının karşısındaki özel yer işaretlenmek suretiyle oy kullanılır. Siyasi parti gruplarının ikinci fıkraya göre belirlenen kontenjanlarından Kurula seçilecek üyelerin sayısından fazla verilen oylar geçersiz sayılır.
              
              Karar yeter sayısı olmak şartıyla seçimde en çok oyu alan boş üyelik sayısı kadar aday seçilmiş olur.
              
              ç) Üyelerin görev sürelerinin bitiminden iki ay önce; üyeliklerde herhangi bir sebeple boşalma olması hâlinde, boşalma tarihinden veya boşalma tarihinde Türkiye Büyük Millet Meclisi tatilde ise tatilin bitiminden itibaren bir ay içinde aynı usulle seçim yapılır. Bu seçimlerde, boşalan üyeliklerin siyasi parti gruplarına dağılımı, ilk seçimde siyasi parti grupları kontenjanından seçilen üye sayısı ve siyasi parti gruplarının hâlihazırdaki oranı dikkate alınmak suretiyle yapılır.
              
              Cumhurbaşkanı (…) (1) tarafından seçilen üyelerden birinin görev süresinin bitiminden kırk beş gün önce veya herhangi bir sebeple görevin sona ermesi hâlinde durum, on beş gün içinde Kurum tarafından, Cumhurbaşkanlığına (…) (1) bildirilir. Üyelerin görev süresinin dolmasına bir ay kala yeni üye seçimi yapılır. Bu üyeliklerde, görev süresi dolmadan herhangi bir sebeple boşalma olması hâlinde ise bildirimden itibaren on beş gün içinde seçim yapılır. (1)
              
              Kurul, üyeleri arasından Başkan ve İkinci Başkanı seçer. Kurulun Başkanı, Kurumun da başkanıdır.
              
              Kurul üyelerinin görev süresi dört yıldır. Süresi biten üye yeniden seçilebilir. Görev süresi dolmadan herhangi bir sebeple görevi sona eren üyenin yerine seçilen kişi, yerine seçildiği üyenin kalan süresini tamamlar.
              
              Seçilen üyeler Yargıtay Birinci Başkanlık Kurulu huzurunda “Görevimi Anayasaya ve kanunlara uygun olarak, tam bir tarafsızlık, dürüstlük, hakkaniyet ve adalet anlayışı içinde yerine getireceğime, namusum ve şerefim üzerine yemin ederim.” şeklinde yemin ederler. Yargıtaya yemin için yapılan başvuru acele işlerden sayılır.
              
              ––––––––––––––––
              
              2/7/2018 tarihli ve 703 sayılı KHK’nin 163. maddesiyle, bu fıkrada yer alan “veya Bakanlar Kurulu” ve “veya Bakanlar Kuruluna sunulmak üzere Başbakanlığa” ibareleri madde metninden çıkarılmıştır.
              
              
              
              Kurul üyeleri özel bir kanuna dayanmadıkça, Kuruldaki resmî görevlerinin yürütülmesi dışında resmî veya özel hiçbir görev alamaz, dernek, vakıf, kooperatif ve benzeri yerlerde yöneticilik yapamaz, ticaretle uğraşamaz, serbest meslek faaliyetinde bulunamaz, hakemlik ve bilirkişilik yapamazlar. Ancak, Kurul üyeleri, asli görevlerini aksatmayacak şekilde bilimsel amaçlı yayın yapabilir, ders ve konferans verebilir ve bunlardan doğacak telif hakları ile ders ve konferans ücretlerini alabilirler.
              
              Üyelerin görevleri sebebiyle işledikleri iddia edilen suçlara ilişkin soruşturmalar 2/12/1999 tarihli ve 4483 sayılı Memurlar ve Diğer Kamu Görevlilerinin Yargılanması Hakkında Kanuna göre yapılır ve bunlar hakkında soruşturma izni Cumhurbaşkanı tarafından verilir. (1)
              
              Kurul üyeleri hakkında yapılacak disiplin soruşturması ve kovuşturmasında 657 sayılı Kanun hükümleri uygulanır.
              
              Kurul üyelerinin süreleri dolmadan herhangi bir nedenle görevlerine son verilemez. Kurul üyelerinin;
              
              Seçilmek için gereken şartları taşımadıklarının sonradan anlaşılması,
              
              Görevleriyle ilgili olarak işledikleri suçlardan dolayı haklarında verilen mahkûmiyet kararının kesinleşmesi,
              
              Görevlerini yerine getiremeyeceklerinin sağlık kurulu raporuyla kesin olarak tespit edilmesi,
              
              ç) Görevlerine izinsiz, mazeretsiz ve kesintisiz olarak on beş gün ya da bir yılda toplam otuz gün süreyle devam etmediklerinin tespit edilmesi,
              
              Bir ay içinde izinsiz ve mazeretsiz olarak toplam üç, bir yıl içinde toplam on kurul toplantısına katılmadıklarının tespit edilmesi, hâllerinde kurul kararıyla üyelikleri sona erer.
              
              Kurul üyeliğine seçilenlerin Kurulda görev yaptıkları sürece önceki görevleri ile olan ilişikleri kesilir. Kamu görevlisi iken üyeliğe seçilenler, memuriyete giriş şartlarını kaybetmemeleri kaydıyla, görev sürelerinin sona ermesi veya görevden ayrılma isteğinde bulunmaları ve otuz gün içinde eski kurumlarına başvurmaları durumunda atamaya yetkili makam tarafından bir ay içinde mükteseplerine uygun bir kadroya atanır. Atama gerçekleşinceye kadar, bunların almakta oldukları her türlü ödemelerin Kurum tarafından ödenmesine devam olunur. Bir kamu kurumunda çalışmayanlardan üyeliğe seçilip yukarıda belirtilen şekilde görevi sona erenlere herhangi bir görev veya işe başlayıncaya kadar, almakta oldukları her türlü ödemeler Kurum tarafından ödenmeye devam edilir ve bu şekilde üyeliği sona erenlere Kurum tarafından yapılacak ödeme üç ayı geçemez. Bunların Kurumda geçirdiği süreler, özlük ve diğer hakları açısından önceki kurum veya kuruluşlarında geçirilmiş sayılır.
              
              
              
              Kurulun görev ve yetkileri
              
              MADDE 22- (1) Kurulun görev ve yetkileri şunlardır:
              
              Kişisel verilerin, temel hak ve özgürlüklere uygun şekilde işlenmesini sağlamak.
              
              Kişisel verilerle ilgili haklarının ihlal edildiğini ileri sürenlerin şikâyetlerini karara bağlamak.
              
              
              
              ––––––––––––––––
              
              2/7/2018 tarihli ve 703 sayılı KHK’nin 163. maddesiyle, bu fıkrada yer alan “Başbakan” ibaresi “Cumhurbaşkanı” şeklinde değiştirilmiştir.
              
              
              
              Şikâyet üzerine veya ihlal iddiasını öğrenmesi durumunda resen görev alanına giren konularda kişisel verilerin kanunlara uygun olarak işlenip işlenmediğini incelemek ve gerektiğinde bu konuda geçici önlemler almak.
              
              ç) Özel nitelikli kişisel verilerin işlenmesi için aranan yeterli önlemleri belirlemek.
              
              Veri Sorumluları Sicilinin tutulmasını sağlamak.
              
              Kurulun görev alanı ile kurumun işleyişine ilişkin konularda gerekli düzenleyici işlemleri yapmak.
              
              Veri güvenliğine ilişkin yükümlülükleri belirlemek amacıyla düzenleyici işlem yapmak.
              
              Veri sorumlusunun ve temsilcisinin görev, yetki ve sorumluluklarına ilişkin düzenleyici işlem yapmak.
              
              ğ) Bu kanunda öngörülen idari yaptırımlara karar vermek.
              
              Diğer kurum ve kuruluşlarca hazırlanan ve kişisel verilere ilişkin hüküm içeren mevzuat taslakları hakkında görüş bildirmek.
              
              ı) Kurumun; stratejik planını karara bağlamak, amaç ve hedeflerini, hizmet kalite standartlarını ve performans kriterlerini belirlemek.
              
              Kurumun stratejik planı ile amaç ve hedeflerine uygun olarak hazırlanan bütçe teklifini görüşmek ve karara bağlamak.
              
              Kurumun performansı, mali durumu, yıllık faaliyetleri ve ihtiyaç duyulan konular hakkında hazırlanan rapor taslaklarını onaylamak ve yayımlamak.
              
              Taşınmaz alımı, satımı ve kiralanması konularındaki önerileri görüşüp karara bağlamak.
              
              Kanunlarla verilen diğer görevleri yerine getirmek.
              
              
              
              Kurulun çalışma esasları
              
              MADDE 23- (1) Kurulun toplantı günlerini ve gündemini başkan belirler. Başkan gereken hâllerde kurulu olağanüstü toplantıya çağırabilir.
              
              Kurul, başkan dâhil en az altı üye ile toplanır ve üye tam sayısının salt çoğunluğuyla karar alır. Kurul üyeleri çekimser oy kullanamaz.
              
              Kurul üyeleri; kendilerini, üçüncü dereceye kadar kan ve ikinci dereceye kadar kayın hısımlarını, evlatlıklarını ve aralarındaki evlilik bağı kalkmış olsa bile eşlerini ilgilendiren konularla ilgili toplantı ve oylamaya katılamaz.
              
              Kurul üyeleri çalışmaları sırasında ilgililere ve üçüncü kişilere ait öğrendikleri sırları bu konuda kanunen yetkili kılınan mercilerden başkasına açıklayamazlar ve kendi yararlarına kullanamazlar. Bu yükümlülük görevden ayrılmalarından sonra da devam eder.
              
              Kurulda görüşülen işler tutanağa bağlanır. Kararlar ve varsa karşı oy gerekçeleri karar tarihinden itibaren en geç on beş gün içinde yazılır. Kurul, gerekli gördüğü kararları kamuoyuna duyurur.
              
              Aksi kararlaştırılmadıkça, kurul toplantılarındaki görüşmeler gizlidir.
              
              Kurulun çalışma usul ve esasları ile kararların yazımı ve diğer hususlar yönetmelikle düzenlenir.
              
              
              
              Başkan
              
              MADDE 24- (1) Başkan, kurul ve kurumun başkanı sıfatıyla kurumun en üst amiri olup kurum hizmetlerini mevzuata, kurumun amaç ve politikalarına, stratejik planına, performans ölçütlerine ve hizmet kalite standartlarına uygun olarak düzenler, yürütür ve hizmet birimleri arasında koordinasyonu sağlar.
              
              Başkan, kurumun genel yönetim ve temsilinden sorumludur. Bu sorumluluk, kurum çalışmalarının düzenlenmesi, yürütülmesi, denetlenmesi, değerlendirilmesi ve gerektiğinde kamuoyuna duyurulması görev ve yetkilerini kapsar.
              
              Başkanın görevleri şunlardır:
              
              Kurul toplantılarını idare etmek.
              
              Kurul kararlarının tebliğini ve kurulca gerekli görülenlerin kamuoyuna duyurulmasını sağlamak ve uygulanmalarını izlemek.
              
              Başkan yardımcısını, daire başkanlarını ve kurum personelini atamak. ç)Hizmet birimlerinden gelen önerilere son şeklini vererek kurula sunmak.
              
              Stratejik planın uygulanmasını sağlamak, hizmet kalite standartları doğrultusunda insan kaynakları ve çalışma politikalarını oluşturmak.
              
              Belirlenen stratejilere, yıllık amaç ve hedeflere uygun olarak Kurumun yıllık bütçesi ile mali tablolarını hazırlamak.
              
              Kurul ve hizmet birimlerinin uyumlu, verimli, disiplinli ve düzenli bir biçimde çalışması amacıyla koordinasyonu sağlamak.
              
              Kurumun diğer kuruluşlarla ilişkilerini yürütmek.
              
              ğ) Kurum başkanı adına imzaya yetkili personelin görev ve yetki alanını belirlemek.
              
              Kurumun yönetim ve işleyişine ilişkin diğer görevleri yerine getirmek.
              
              Kurum başkanının yokluğunda ikinci başkan, başkana vekalet eder.
              
              
              
              Başkanlığın oluşumu ve görevleri
              
              MADDE 25- (1) Başkanlık; Başkan Yardımcısı ve hizmet birimlerinden oluşur. Başkanlık, dördüncü fıkrada sayılan görevleri daire başkanlıkları şeklinde teşkilatlanan hizmet birimleri aracılığıyla yerine getirir. Daire başkanlıklarının sayısı yediyi geçemez.
              
              Başkan tarafından, Kuruma ilişkin görevlerinde yardımcı olmak üzere bir Başkan Yardımcısı atanır.
              
              Başkan Yardımcısı ve daire başkanları; en az dört yıllık yükseköğretim kurumu mezunu, on yıl süreyle kamu hizmetinde bulunan kişiler arasından Başkan tarafından atanır.
              
              Başkanlığın görevleri şunlardır:
              
              Veri Sorumluları Sicilini tutmak.
              
              Kurumun ve Kurulun büro ve sekretarya işlemlerini yürütmek.
              
              Kurumun taraf olduğu davalar ile icra takiplerinde avukatlar vasıtasıyla Kurumu temsil etmek, davaları takip etmek veya ettirmek, hukuk hizmetlerini yürütmek.
              
              
              
              ç) Kurul üyeleri ile Kurumda görev yapanların özlük işlemlerini yürütmek.
              
              Kanunlarla mali hizmet ve strateji geliştirme birimlerine verilen görevleri yapmak.
              
              Kurumun iş ve işlemlerinin yürütülmesi amacıyla bilişim sisteminin kurulmasını ve kullanılmasını sağlamak.
              
              Kurulun yıllık faaliyetleri hakkında veya ihtiyaç duyulan konularda rapor taslaklarını hazırlamak ve Kurula sunmak.
              
              Kurumun stratejik plan taslağını hazırlamak.
              
              ğ) Kurumun personel politikasını belirlemek, personelin kariyer ve eğitim planlarını hazırlamak ve uygulamak.
              
              Personelin atama, nakil, disiplin, performans, terfi, emeklilik ve benzeri işlemlerini yürütmek.
              
              ı) Personelin uyacağı etik kuralları belirlemek ve gerekli eğitimi vermek.
              
              10/12/2003 tarihli ve 5018 sayılı Kamu Malî Yönetimi ve Kontrol Kanunu çerçevesinde Kurumun ihtiyacı olan her türlü satın alma, kiralama, bakım, onarım, yapım, arşiv, sağlık, sosyal ve benzeri hizmetleri yürütmek.
              
              Kuruma ait taşınır ve taşınmazların kayıtlarını tutmak.
              
              Kurul veya başkan tarafından verilen diğer görevleri yapmak.
              
              Hizmet birimleri ile bu birimlerin çalışma usul ve esasları, bu Kanunda belirtilen faaliyet alanı, görev ve yetkilere uygun olarak Kurumun teklifi üzerine Cumhurbaşkanınca yürürlüğe konulan yönetmelikle belirlenir. (1)
              
              
              
              Kişisel Verileri Koruma Uzmanı ve uzman yardımcıları
              
              MADDE 26- (1) Kurumda, Kişisel Verileri Koruma Uzmanı ve Kişisel Verileri Koruma Uzman Yardımcısı istihdam edilebilir. Bunlardan 657 sayılı Kanunun ek 41 inci maddesi çerçevesinde Kişisel Verileri Koruma Uzmanı kadrosuna atananlara bir defaya mahsus olmak üzere bir derece yükseltilmesi uygulanır.
              
              
              
              Personele ve özlük haklarına ilişkin hükümler
              
              MADDE 27- (1) Kurum personeli, bu Kanunla düzenlenen hususlar dışında 657 sayılı Kanuna tabidir.
              
              Kurul Başkan ve üyeleri ile Kurum personeline 27/6/1989 tarihli ve 375 sayılı Kanun Hükmünde Kararnamenin ek 11 inci maddesi uyarınca belirlenmiş emsali personele mali ve sosyal haklar kapsamında yapılan ödemeler aynı usul ve esaslar çerçevesinde ödenir. Emsali personele yapılan ödemelerden vergi ve diğer yasal kesintilere tabi olmayanlar bu Kanuna göre de vergi ve diğer kesintilere tabi olmaz.
              
              Kurul Başkan ve üyeleri ile Kurum personeli 31/5/2006 tarihli ve 5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanununun 4. maddesinin birinci fıkrasının (c) bendi hükümlerine tabidir. Kurul Başkan ve üyeleri ile Kurum personeli emeklilik hakları bakımından da emsali olarak belirlenen personel ile denk kabul edilir. 5510 sayılı Kanunun 4. maddesinin
              
              –––––––––––––––––
              
              2/7/2018 tarihli ve 703 sayılı KHK’nin 163. maddesiyle, bu fıkrada yer alan “Bakanlar Kurulu kararıyla” ibaresi “Cumhurbaşkanınca” şeklinde değiştirilmiştir.
              
              
              
              birinci fıkrasının (c) bendi kapsamında sigortalı iken Kurul Başkanı ve üyeliklerine atananlardan bu görevleri sona erenler veya bu görevlerinden ayrılma isteğinde bulunanların bu görevlerde geçen hizmet süreleri kazanılmış hak aylık, derece ve kademelerinin tespitinde dikkate alınır. Bunlardan bu görevleri sırasında 5510 sayılı Kanunun geçici 4. maddesi kapsamına girenlerin bu görevlerde geçen süreleri makam tazminatı ile temsil tazminatı ödenmesi gereken süre olarak değerlendirilir. Kamu kurum ve kuruluşlarında 5510 sayılı Kanunun 4. maddesinin birinci fıkrasının (a) bendi kapsamında sigortalı iken Kurul Başkanı ve üyeliklerine atananların, önceki kurum ve kuruluşları ile ilişiklerinin kesilmesi kendilerine kıdem tazminatı veya iş sonu tazminatı ödenmesini gerektirmez. Bu durumda olanların kıdem tazminatı veya iş sonu tazminatı ödenmesi gereken hizmet süreleri, Kurul Başkanı ile Kurul üyeliği olarak geçen hizmet süreleri ile birleştirilir ve emeklilik ikramiyesi ödenecek süre olarak değerlendirilir.
              
              Merkezi yönetim kapsamındaki kamu idarelerinde, sosyal güvenlik kurumlarında, mahallî idarelerde, mahallî idarelere bağlı idarelerde, mahallî idare birliklerinde, döner sermayeli kuruluşlarda, kanunlarla kurulan fonlarda, kamu tüzel kişiliğini haiz kuruluşlarda, sermayesinin yüzde ellisinden fazlası kamuya ait kuruluşlarda, iktisadi devlet teşekkülleri ve kamu iktisadi kuruluşları ile bunlara bağlı ortaklıklar ve müesseselerde görevli memurlar ile diğer kamu görevlileri kurumlarının muvafakati, hâkimler ve savcılar ise kendilerinin muvafakati ile aylık, ödenek, her türlü zam ve tazminatlar ile diğer mali ve sosyal hak ve yardımları kurumlarınca ödenmek kaydıyla geçici olarak Kurumda görevlendirilebilir. Kurumun bu konudaki talepleri, ilgili kurum ve kuruluşlarca öncelikle sonuçlandırılır. Bu şekilde görevlendirilen personel, kurumlarından aylıklı izinli sayılır. Bu personelin izinli oldukları sürece memuriyetleri ile ilgileri ve özlük hakları devam ettiği gibi, bu süreler yükselme ve emekliliklerinde de hesaba katılır ve yükselmeleri başkaca bir işleme gerek duyulmadan süresinde yapılır. Bu madde kapsamında görevlendirilenlerin, Kurumda geçirdikleri süreler, kendi kurumlarında geçirilmiş sayılır. Bu şekilde görevlendirilenlerin sayısı Kişisel Verileri Koruma Uzmanı ve Kişisel Verileri Koruma Uzman Yardımcısı toplam kadro sayısının yüzde onunu aşamaz ve görevlendirme süresi iki yılı geçemez. Ancak ihtiyaç hâlinde bu süre bir yıllık dönemler hâlinde uzatılabilir. (1)
              
              Kurumda istihdam edilecek personele ilişkin kadro unvan ve sayıları ekli (I) sayılı cetvelde gösterilmiştir. Toplam kadro sayısını geçmemek üzere 13/12/1983 tarihli ve 190 sayılı Genel Kadro ve Usulü Hakkında Kanun Hükmünde Kararnamenin eki cetvellerde yer alan kadro unvanlarıyla sınırlı olmak kaydıyla unvan ve derece değişikliği yapma, yeni unvan ekleme ve boş kadroların iptali Kurul kararıyla yapılır.
              
              
              
              YEDİNCİ BÖLÜM
              
              Çeşitli Hükümler
              
              İstisnalar
              
              MADDE 28- (1) Bu Kanun hükümleri aşağıdaki hâllerde uygulanmaz:
              
              Kişisel verilerin, üçüncü kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak kaydıyla gerçek kişiler tarafından tamamen kendisiyle veya aynı konutta yaşayan aile fertleriyle ilgili faaliyetler kapsamında işlenmesi.
              
              
              
              28/11/2017 tarihli ve 7061 sayılı Kanunun 119 uncu maddesiyle bu fıkrada yer alan “diğer kamu görevlileri kurumlarının muvafakati” ibaresinden sonra gelmek üzere “, hâkimler ve savcılar ise kendilerinin muvafakati” ibaresi eklenmiştir.
              
              
              
              Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi.
              
              Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi.
              
              ç) Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi.
              
              Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları veya infaz mercileri tarafından işlenmesi.
              
              Bu Kanunun amacına ve temel ilkelerine uygun ve orantılı olmak kaydıyla veri sorumlusunun aydınlatma yükümlülüğünü düzenleyen 10 uncu, zararın giderilmesini talep etme hakkı hariç, ilgili kişinin haklarını düzenleyen 11 inci ve Veri Sorumluları Siciline kayıt yükümlülüğünü düzenleyen 16. maddeleri aşağıdaki hâllerde uygulanmaz:
              
              Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli
              
              olması.
              
              İlgili kişinin kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi.
              
              Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu
              
              kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli olması.
              
              ç) Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak devletin ekonomik ve mali çıkarlarının korunması için gerekli olması.
              
              
              
              Kurumun bütçesi ve gelirleri
              
              MADDE 29- (1) Kurumun bütçesi, 5018 sayılı Kanunda belirlenen usul ve esaslara göre hazırlanır ve kabul edilir.
              
              (2) Kurumun gelirleri şunlardır:
              
              Genel bütçeden yapılacak hazine yardımları.
              
              Kuruma ait taşınır ve taşınmazlardan elde edilen gelirler.
              
              Alınan bağış ve yardımlar.
              
              ç) Gelirlerinin değerlendirilmesinden elde edilen gelirler.
              
              Diğer gelirler.
              
              
              
              Değiştirilen ve eklenen hükümler
              
              MADDE 30- (1) (10/12/2003 tarihli ve 5018 sayılı Kanun ile ilgili olup yerine işlenmiştir.)
              
              (2) ila (5)- (26/9/2004 tarihli ve 5237 sayılı Kanun ile ilgili olup yerine işlenmiştir.)
              
              (7/5/1987 tarihli ve 3359 sayılı Sağlık Hizmetleri Temel Kanunu ile ilgili olup yerine işlenmiştir.)
              
              12316
              
              
              
              (11/10/2011 tarihli ve 663 sayılı Sağlık Bakanlığı ve Bağlı Kuruluşlarının Teşkilat ve Görevleri Hakkında Kanun Hükmünde Kararname ile ilgili olup yerine işlenmiştir.)
              
              
              
              Yönetmelik
              
              MADDE 31- (1) Bu Kanunun uygulanmasına ilişkin yönetmelikler Kurum tarafından yürürlüğe konulur.
              
              
              
              Geçiş hükümleri
              
              GEÇİCİ MADDE 1- (1) Bu Kanunun yayımı tarihinden itibaren altı ay içinde 21 inci maddede öngörülen usule göre Kurul üyeleri seçilir ve Başkanlık teşkilatı oluşturulur.
              
              Veri sorumluları, Kurul tarafından belirlenen ve ilan edilen süre içinde Veri Sorumluları Siciline kayıt yaptırmak zorundadır.
              
              Bu Kanunun yayımı tarihinden önce işlenmiş olan kişisel veriler, yayımı tarihinden itibaren iki yıl içinde bu Kanun hükümlerine uygun hâle getirilir. Bu Kanun hükümlerine aykırı olduğu tespit edilen kişisel veriler derhâl silinir, yok edilir veya anonim hâle getirilir. Ancak bu Kanunun yayımı tarihinden önce hukuka uygun olarak alınmış rızalar, bir yıl içinde aksine bir irade beyanında bulunulmaması hâlinde, bu Kanuna uygun kabul edilir.
              
              Bu Kanunda öngörülen yönetmelikler bu Kanunun yayımı tarihinden itibaren bir yıl içinde yürürlüğe konulur.
              
              Bu Kanunun yayımı tarihinden itibaren bir yıl içinde, kamu kurum ve kuruluşlarında bu Kanunun uygulanmasıyla ilgili koordinasyonu sağlamak üzere üst düzey bir yönetici belirlenerek Başkanlığa bildirilir.
              
              İlk seçilen Başkan, İkinci Başkan ve kura ile belirlenen iki üye altı yıl; diğer beş üye ise dört yıl görev yapar.
              
              Kuruma bütçe tahsis edilene kadar;
              
              Kurumun giderleri Başbakanlık bütçesinden karşılanır.
              
              Kurumun hizmetlerini yerine getirmesi amacıyla bina, araç, gereç, mefruşat ve donanım gibi gerekli tüm destek hizmetleri Başbakanlıkça sağlanır.
              
              Kurumun hizmet birimleri faaliyete geçinceye kadar sekretarya hizmetleri Başbakanlık tarafından yerine getirilir.
              
              
              
              GEÇİCİ MADDE 2- (Ek:28/11/2017-7061/120 Md.)
              
              (1) En az dört yıllık lisans öğrenimi veren siyasal bilgiler, iktisadi ve idari bilimler, iktisat, hukuk ve işletme fakültelerinden, mühendislik fakültelerinin elektronik, elektrik-elektronik, elektronik ve haberleşme, bilgisayar, bilişim sistemleri mühendisliği bölümlerinden ya da bunlara denkliği Yükseköğretim Kurulu tarafından kabul edilen yurt içi ve yurt dışındaki yükseköğrenim kurumlarından mezun olanlardan; mesleğe özel yarışma sınavı ile girilen ve belirli süreli meslek içi eğitimden ve özel bir yeterlik sınavından sonra 657 sayılı Kanunun 36, maddesinin “Ortak Hükümler” başlıklı bölümünün (A) fıkrasının (11) numaralı bendinde belirtilen unvanlara ilişkin kurumların merkez teşkilatlarına ait kadrolara atanmış ve bu kadrolarda aylıksız izin süreleri hariç en az iki yıl bulunmuş olanlar ile öğretim üyesi kadrolarında bulunanlar, Yabancı Dil Bilgisi Seviye Tespit Sınavından en az yetmiş puan almış olmak ve atama tarihi itibarıyla kırk yaşından gün almamış olmak kaydıyla, bu maddenin yürürlüğe girdiği tarihten itibaren bir yıl içinde Kişisel Verileri Koruma Uzmanı olarak atanabilirler. Bu şekilde atanacakların sayısı on beşi geçemez.
              
              Yürürlük
              
              MADDE 32- (1) Bu Kanunun;
              
              8., 9.,11., 13., 14.,15., 16., 17. ve 18. maddeleri yayımı tarihinden altı ay sonra,
              
              Diğer maddeleri ise yayımı tarihinde, yürürlüğe girer.
              
              
              
              Yürütme
              
              MADDE 33- (1) Bu Kanun hükümlerini Bakanlar Kurulu yürütür.
              `)}
            </div>
          </div>
        </div>
        <div className="productivity__center center">
          <div
            className="offer__container h4"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
            Personal Data Protection
            </h3>
          </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(`
          
              `)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kvkk;
