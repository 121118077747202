import React, { useEffect, useState } from "react";
import AOS from "aos";
import VideoCard from "./components/videoCard";
import {cocukOzguven, ebeveyenliginPrensibi, kardesKiskancligi,yalansoyleme} from '../../data/training.sets.data'

const VideoArticle = () => {

  const [data, setData] = useState({})

  useEffect(() => {
    AOS.init();
    const queryParams = new URLSearchParams(window.location.search);
    const videoId = queryParams.get("id");

    switch (videoId) {
      case "1":
        setData(kardesKiskancligi)
        break;
      case "2" :
        setData(yalansoyleme)
        break;
      case "5":
        setData(ebeveyenliginPrensibi)
        break;
      case "6":
        setData(cocukOzguven)
        break
      default:
        setData(kardesKiskancligi)
        break;
    }

  }, []);

  return (
    <div className="page">
      <div className="productivity">
        <div className="download">
          <div className="download__center center">
            <div className="offer__container h4">
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
                  <span style={{ color: "#ffffff" }}>
                    {data?.pageTitle}
                  </span>
              </h3>
            </div>
          </div>
          <div className="review__center" style={{ marginTop: 40 }}>
            <div class="download__info">
              {data?.pageDescription}
            </div>
          </div>
        </div>
        <div className="productivity__center center" style={{ marginTop: -30 }}>
            <div className="productivity__container">
              {data?.videos?.map((element) => {
                return (
                  <VideoCard
                    title={element.videoTitle}
                    url={element.videoUrl}
                  />
                );
              })}
            </div>
        </div>
      </div>
    </div>
  );
};

export default VideoArticle;
