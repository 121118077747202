export const iconAndProblems= [
    {
      icon: "",
      name: "Uyku",
    },
    {
      icon: "",
      name: "Beslenme",
    },
    {
      icon: "",
      name: "Ağlama",
    },
    {
      icon: "",
      name: "Hiperaktivite",
    },
    {
      icon: "",
      name: "Ödev Yapmama",
    },
    {
      icon: "",
      name: "Boşanma",
    },
    {
      icon: "",
      name: "Kardeş Kıskançlığı",
    },
    {
      icon: "",
      name: "Utangaçlık",
    },
    {
      icon: "",
      name: "Kaygı",
    },
    {
      icon: "",
      name: "Öfke",
    },
    {
      icon: "",
      name: "Hırçınlık",
    },
    {
      icon: "",
      name: "İnatçılık",
    },
    {
      icon: "",
      name: "Vurma",
    },
    {
      icon: "",
      name: "Zorbalık",
    },
    {
      icon: "",
      name: "Şiddet",
    },
    {
      icon: "",
      name: "Öğrenme Güçlüğü",
    },
    {
      icon: "",
      name: "Ergenlik",
    },
    {
      icon: "",
      name: "Ergenlikte Romantik İlişkiler",
    },
    {
      icon: "",
      name: "Teknoloji Kullanımı/ Bağımlılığı",
    },
    {
      icon: "",
      name: "Zor Davranışlar",
    },
    {
      icon: "",
      name: "Sınır Koyma",
    },
    {
      icon: "",
      name: "Sorumsuzluk",
    },
    {
      icon: "",
      name: "Travma",
    },
    {
      icon: "",
      name: "Ölüm ve Yas",
    },
    {
      icon: "",
      name: "Tik Bozukluğu",
    },
    {
      icon: "",
      name: "Kariyer Planlama",
    },
    {
      icon: "",
      name: "Yetenek Keşfi",
    },
    {
      icon: "",
      name: "Üstün Zekalı Çocuklar",
    },
    {
      icon: "",
      name: "Meslek Seçimi",
    },
    {
      icon: "",
      name: "Finansal Okuryazarlık",
    },
    {
      icon: "",
      name: "Dijital Okuryazarlık",
    },
    {
      icon: "",
      name: "Stres ile Baş Etme",
    },
    {
      icon: "",
      name: "Kaygı Yönetimi",
    },
    {
      icon: "",
      name: "Özgüvensizlik",
    },
    {
      icon: "",
      name: "Okulda Telefon Kullanımı",
    },
    {
      icon: "",
      name: "Kronik Hastalıklar",
    },
    {
      icon: "",
      name: "Ev ve Okul Değişimi",
    },
    {
      icon: "",
      name: "Geniş Ailede Çocuk Büyütmek",
    },
    {
      icon: "",
      name: "Öğrenme Güçlüğü",
    },
    {
      icon: "",
      name: "Ayrılık Kaygısı",
    },
    {
      icon: "",
      name: "Kitap Okumayı Sevdirme",
    },
    {
      icon: "",
      name: "Tırnak Yeme",
    },
    {
      icon: "",
      name: "Korku",
    },
    {
      icon: "",
      name: "Kaygıyı Yönetme",
    },
    {
      icon: "",
      name: "Evcil Hayvan ile Çocuk Büyütme",
    },
    {
      icon: "",
      name: "Deprem ve Travmaların Çocuğa Anlatılması",
    },
    {
      icon: "",
      name: "Okula Hazırlık Süreci",
    },
    {
      icon: "",
      name: "Oyun",
    },
    {
      icon: "",
      name: "Hassas Çocuklar",
    },
    {
      icon: "",
      name: "Özel Gereksinimli Çocuk Yetiştirmek",
    },
    {
      icon: "",
      name: "Spor ve Çocuk Gelişimi",
    },
    {
      icon: "",
      name: "Sürekli Özür Dileyen Çocuklar",
    },
    {
      icon: "",
      name: "Üstün Zekalı Çocuklar",
    },
  ]

  export const sampleVideos = [
    {
      title:"Kardeş Kıskançlığı",
      subtitle:"Kardeş Kıskançlığı Eğitim Serisi (10 Video)",
      id:1
    },
    {
      title:"Yalan Söyleme",
      subtitle:"Yalan Söyleme Eğitim Serisi (9 Video)",
      id:2
    }
  ]

  export const sampleArticles = [
    {
      title:"Sınav Kaygısı",
      subtitle: "Bu dosyamızda 6 makale bulunmaktadır. Sadece birinci makale örneği aşağıda gösterilmiştir.",
      id:3
    },
    {
      title:"Kardeş Kıskançlığı",
      subtitle: "Bu dosyamızda 15 makale bulunmaktadır. Sadece birinci makale örneği aşağıda gösterilmiştir.", 
      id:4
    }
  ]