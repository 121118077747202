export const earningsList = [
    "Kendi ebeveynlik anlayışının temellerini keşfedecek",
    "Çocuğuna karşı sergilediği ebeveynlik davranışlarını analiz edecek",
    "Çocuğuyla gerçek sevgi bağı kurmayı öğrenecek",
    "Çocuklarıyla koşulsuz ilişki kurmayı öğrenecek",
    "Birey olmanın prensiplerini öğrenecek",
    "Sorumluluk bilinci kazandırmayı öğrenecek",
    "Mutlu çocuk yetiştirmenin temel prensiplerini anlayacak",
    "Başarılı çocuk yetiştirmenin yöntemini öğrenecek",
    "Çocuklarda sorunların çıkmasını engelleyecek"
]

export const parentSchoolTopics = [
    {
        title:"Etkili Ebeveynlik",
        subtitle:"Etkili Ebeveynliğin 7 temel prensibi nedir?",
    },
    {
        title:"Ebeveyn Türleri",
        subtitle: "Ebeveyn Türleri Nelerdir? Ebeveynlerin, ebeveynlik türlerinin çocuğun hayatı üzerindeki etkisi nedir?",
    },
    {
        title:"Bağlanma",
        subtitle:  "Bağlanma türleri nelerdir? Çocukla güvenli bağlanma nasıl kurulabilir?",
    },
    {
        title:"Ben Dili",
        subtitle: "Duygular ben dili yanlış kullanılmadan nasıl ifade edilir?",
    },
    {
        title:"İç Motivasyon",
        subtitle: "İç motivasyon nedir? Çocuğun iç motivasyonu nasıl oluşturulur?",
    },
    {
        title:"Özgüven",
        subtitle: "Özgüven neden önemlidir? Çocukta özgüven nasıl oluşturulur?",
    },
    {
        title:"İmaj Kaygısı",
        subtitle:"İmaj kaygısı nedir? Çocukta imaj kaygısı oluşmasına nasıl engel olunur?",
    },
    {
        title:"Ödül",
        subtitle:"Çocukla ödül ve cezasız nasıl iletişim kurulur?"
    },
    {
        title:"Bağ Kurma",
        subtitle:"Çocukla güvenli ilişki nasıl kurulur?"
    },
    {
        title:"Bağlanma",
        subtitle:"Çocukla güvenli bağlanma nasıl sağlanır? Güvenli bağlanmanın önemi nedir? Bağlanma türleri nelerdir?",
    },
    {
        title:"İç ve Dış Kaynaklı Birey",
        subtitle: "İç kaynaklı ve dış kaynaklı nedir? İç kaynaklı birey olmak neden önemlidir?",
    },
    {
        title:"Mutluluk",
        subtitle: "Mutluluğun kaynakları nelerdir?",
    },
    {
        title:"Sevgi",
        subtitle: "Çocuğa koşulsuz sevgi sunmanın önemi nedir?"
    },
    {
        title:"Mutlu ve Başarılı Çocuk Nasıl Yetiştirilir?",
        subtitle: "Mutluluk ve başarı arasındaki doğru ilişki nasıl kurulmalıdır?",
    },
    {
        title:"Yaralarımız ve Askerler",
        subtitle: "Savunma mekanizmalarımız, göstermek istemediğimiz güçsüz yanlarımızı nasıl korurlar?",
    },
    {
        title:"Sağlıklı Sınır Koymak",
        subtitle: "Çocuklara sağlıklı sınır nasıl konulmalıdır? Sınır içinde sevgi nasıl verilir?",
    },
    {
        title:"Rutin Oluşturmak",
        subtitle: "Evde rutin nasıl oluşturulur? Rutin neden önemlidir?",
    },
    {
        title:"Alışkanlık Kazandırma",
        subtitle: "Çocuğa alışkanlık nasıl kazandırılır?",
    },
    {
        title:"Sorumluluk Kazandırma",
        subtitle: "Çocuğa nasıl sorumluluk kazandırılabilir?",
    },
    {
        title:"Övgü",
        subtitle:"Çocukla övgüsüz ilişki  kurmanın önemi nedir? Nasıl övgüsüz ilişki kurulabilir?", 
    },
    {
        title:"Oyun",
        subtitle:"Çocukla bağ kurarak nasıl oyun oynanabilir?",
    },
    {
        title:"Mizaç",
        subtitle: "Çocuğun mizacı nasıl keşfedilir?",
    },
    {
        title:"İç Disiplin",
        subtitle:"Çocukta iç disiplin nasıl oluşturulur? Önemi nedir?",
    },
    {
        title:"Başarı",
        subtitle: "Başarının çocuğun ve ailenin hayatındaki yeri ne olmalıdır? Ne zaman başarı arzusu sağlıklıdır?",
    },
    {
        title:"Finansal Okuryazarlık",
        subtitle: "Çocuğa finansal okuryazarlık öğretisi nasıl kazandırılabilir?",
    },
    {
        title:"Merak",
        subtitle: "Çocukta merak duygusu nasıl geliştirilebilir?",
    },
    {
        title:"Kaygı",
        subtitle: "Kaygı duygusu neden ortaya çıkar? Çocuğa kaygı ile baş etmek nasıl öğretilir?",
    },
    {
        title:"Teknoloji Kullanımı",
        subtitle: "Çocuğa üretim odaklı teknoloji kullanımı nasıl öğretilir?",
    },
    {
        title:"Öğrenme ve Beceri",
        subtitle:    "Çocuğun öğrenme becerisi nasıl desteklenir?",
    },
    {
        title:"Spor ve Çocuk",
        subtitle:    "Spor çocuğun hayatına nasıl katkı sağlar?",
    },
    {
        title:"Üniversite Tercih Süreci",
        subtitle:    "Üniversite tercih süreci nasıl sağlıklı yürütülür?",
    },
    {
        title:"Çocuk Yetiştirmede Temel Prensipler",
        subtitle:    "Çocuk yetiştirmede temel prensipler nelerdir?",
    },
    {
        title:"Değersizlik Duygusu",
        subtitle:"Çocuğun değersizlik duygusunun kaynakları neler olabilir?  Çocuğun değersizlik duygusuyla baş etmesine nasıl destek olunur?",
    },
    {
        title:"Okuma Alışkanlığı",
        subtitle:"Çocuğa okuma alışkanlığını nasıl kazandırılır?",
    },
    {
        title:"İletişim",
        subtitle: "Çocuğa sağlıklı ve etkili iletişim kurma nasıl öğretilir?",
    },
    {
        title:"Rekabet",
        subtitle: "Rekabet çocuk için ne zaman sağlıklı olur?",
    },
    {
        title:"Yetenek Gelişimi",
        subtitle:    "Çocuğun yeteneklerini fark etmesi ve geliştirmesine nasıl katkı sağlanır?",
    },
    {
        title:"Duygular",
        subtitle:    "Çocuğun duygularını fark etmesine ve kabullenmesine nasıl destek olunur?",
    },
    {
        title:"Eleştiri",
        subtitle:    "Çocuğun yaşamında eleştirinin etkileri neler olabilir?",
    },
    {
        title:"Koşulsuz ve Koşullu Sevgi",
        subtitle:    "Koşullu ve koşulsuz sevgi nedir? Çocuğun yaşamında koşulsuz sevginin önemi nedir?",
    },
    {
        title:"Cinsellik",
        subtitle: "Çocuğa cinsellik eğitimi nasıl verilir?",
    },
    {
        title:"Mahremiyet Eğitimi: ",
        subtitle: "Çocuğa mahremiyet eğitimi vermenin önemi nedir? Mahremiyet eğitimi ne zaman başlamalıdır?"
    },
]


export const sampleParentSchoolVideos = [
    {
      title:"Ebeveynliğin 7 Temel Prensibi",
      subtitle:"Bu modülümüzde ebeveynlik prensiplerinden bahsedeceğiz.",
      id:5
    },
    {
      title:"Çocuğunuzda Özgüven Oluşturun",
      subtitle:"Bu modülümüzde ‘özgüven’ konusunu işleyeceğiz.",
      id:6
    }
  ]