import logoDark from "./logo-dark.png";
import phoneMockUp from "./figures-1.1.png";
import phoneMockUp2x from "./figures-1.1@2x.png";
import messageBoxMockUp from "./figures-1.2.png";
import messageBoxMockUp2x from "./figures-1.2@2x.png";
import staircase from './figures-2.1.png'
import staircase2x from './figures-2.1@2x.png'
import straightPhone from './figures-2.2.png'
import straightPhone2x from './figures-2.2@2x.png'
import reverseMessageBox from './figures-2.3.png'
import reverseMessageBox2x from './figures-2.3@2x.png'
import smallMessageBox from './figures-2.4.png'
import smallMessageBox2x from './figures-2.4@2x.png'
import arch from './figures-2.5.png'
import arch2x from './figures-2.5@2x.png'
import chatBox from './chat.png';
import chatBox2x from './chat@2x.png';
import cursor from './cursor.png';
import cursor2x from './cursor@2x.png';
import plane from './plane.png';
import plane2x from './plane@2x.png';
import googleSvg from './google.svg';
import appStoreSvg from './app-store.svg';
import appleSvg from './apple.svg'
import googlePlaySvg from './google-play.svg';
import googlePlayIconSvg from './Google_Play-Icon-Logo.wine.svg';
import kidInfoSvg from './parentwiser-cocuk-bilgileri-gir.svg';
import pwDownloadSvg from './parentwiser-indir.svg';
import pwRegistersSvg from './parentwiser-kayit-ol.svg';
import pwPersonalInfoSvg from './parentwiser-kisisel-bilgiler.svg';
import book from './book.png'
import book2x from './book@2x.png'
import mailPng from './parentwiser-mail.png'
import mailPng2x from './parentwiser-mail@2x.png'
import phonePng from './parentwiser-telefon.png'
import phonePng2x from './parentwiser-telefon@2x.png'
import starFill from './star-fill.svg'
import parentSchoolPng from './parentwiser-anne-baba-okulu.png'
import parentSchoolPng2x from './parentwiser-anne-baba-okulu-2x.png'
import trainingsPng from './parentwiser-egitimler.png'
import trainingsPng2x from './parentwiser-egitimler-2x.png'
import knowYourselfPng from './parentwiser-kendinizi-taniyin.png'
import knowYourselfPng2x from './parentwiser-kendinizi-taniyin-2x.png'
import parentSchoolIcon from './parentwiser-anne-baba-okulu-icon.png'
import parentSchoolIcon2x from './parentwiser-anne-baba-okulu-icon-2x.png'
import trainingsIcon from './parentwiser-egitimler-icon.png'
import trainingsIcon2x from './parentwiser-egitimler-icon-2x.png'
import knowYourselfIcon from './parentwiser-kendinizi-taniyin-icon.png'
import knowYourselfIcon2x from './parentwiser-kendinizi-taniyin-icon-2x.png'
import dovePng from './dovePng.png'
import ntvPng from './ntv.png'
import startYellow from './star-yellow.svg'
import startYellowHalf from './star-yellow-half.svg'

const images = {
    logoDark,
    phoneMockUp,
    phoneMockUp2x,
    messageBoxMockUp,
    messageBoxMockUp2x,
    staircase,
    staircase2x,
    straightPhone,
    straightPhone2x,
    reverseMessageBox,
    reverseMessageBox2x,
    smallMessageBox,
    smallMessageBox2x,
    arch,
    arch2x,
    chatBox,
    chatBox2x,
    cursor,
    cursor2x,
    plane,
    plane2x,
    googleSvg,
    appStoreSvg,
    appleSvg,
    googlePlaySvg,
    googlePlayIconSvg,
    kidInfoSvg,
    pwDownloadSvg,
    pwRegistersSvg,
    pwPersonalInfoSvg,
    book,
    book2x,
    mailPng,
    mailPng2x,
    phonePng,
    phonePng2x,
    starFill,
    parentSchoolPng,
    parentSchoolPng2x,
    trainingsPng,
    trainingsPng2x,
    knowYourselfPng,
    knowYourselfPng2x,
    parentSchoolIcon,
    parentSchoolIcon2x,
    trainingsIcon,
    trainingsIcon2x,
    knowYourselfIcon,
    knowYourselfIcon2x,
    dovePng,
    ntvPng,
    startYellow,
    startYellowHalf
}
export default images;