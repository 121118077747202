import parser from "html-react-parser";
import { useEffect } from "react";
import AOS from "aos";

const Usage = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="page">
      <div className="productivity">
        <div className="productivity__center center">
          <div
            className="offer__container h4"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
              Kullanım Sözleşmesi
            </h3>
          </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(
                `
                MADDE 1 – TARAFLAR

İşbu sözleşme ve ekleri (EK-1 Gizlilik Sözleşmesi) “Çifte Havuzlar Mah. Eski Londra Asfaltı Caddesi Kuluçka Merkezi A1 Blok No:151-1C-1B20 Esenler İstanbul” adresinde mukim “Parentwiser Yazılım Anonim Şirketi” (bundan böyle "PW" olarak anılacaktır) ile Parentwiser mobil uygulamasına iş bu sözleşmede belirtilen koşullar dahilinde kayıt olan Kullanıcı ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.

MADDE 2 – TANIMLAR

2.1. Uygulama: PW isimli mobil uygulama, mobil cihazlarda çalışan mobil uygulama yazılımı ve bu yazılım ile bağlantılı olarak oluşturulan ürün ve hizmetlerin sunulduğu tüm sanal mecraları ifade etmektedir. (Bundan böyle "Uygulama" olarak anılacaktır.)

2.2. Kullanıcı: Uygulamadan PW tarafından sunulan hizmetlerden işbu sözleşmede belirtilen koşullar dahilinde yararlanan kişileri ifade etmektedir. (Bundan böyle "Kullanıcı” olarak anılacaktır.)

2.3. Sisteme Erişim Araçları: Kullanıcının hesabını yönetim sayfası, uygulamaya erişim sağlayan kullanıcı adı, şifre, kod, parola gibi yalnızca kullanıcının bilgisinde olan bilgileri ifade eder.

2.4. İletişim Kanalları: Anında bildirim, e-posta, sms, mms, telefon ile bildirim gibi iletişim mecralarını ifade eder.

2.5. Kullanıcı Hesabı Yönetim Sayfası: Kullanıcının yalnızca sisteme erişim araçlarını kullanarak ulaştığı üyeliğine ilişkin iş ve işlemlerde bulunduğu kendisine özel web sayfasını ifade etmektedir. (Bundan böyle kısaca "Portal" olarak anılacaktır.)

MADDE 3 – KONU VE KAPSAM

3.1. İşbu sözleşmenin konusu; kullanıcıya yönelik olarak uygulamada mevcut olan ve ileride PW tarafından sunulacak tüm ürün ve hizmetlerin, bu ürün ve hizmetlerden yararlanma şartlarının ve tarafların hak ve yükümlülüklerinin tespitidir.

3.2. Kullanıcı, işbu sözleşme hükümlerini kabul etmekle, uygulama ve portal içinde yer alan kullanıma, üyeliğe ve hizmetlere ilişkin PW tarafından açıklanan her türlü beyanı da kabul etmiş olmaktadır. Kullanıcı, bahsi geçen beyanlarda belirtilen her türlü hususa uygun olarak davranacağını kabul, beyan ve taahhüt eder.

MADDE 4 – UYGULAMA KULLANIM ŞARTLARI

4.1. Kullanıcı, uygulamanın kullanımından doğan tüm sorumluluğu ve riski üstlendiğini kabul eder. Uygulama yoluyla sağlanan tüm düşünce, tavsiye, hizmet veya diğer bilgi ve materyalin doğruluk, tamlık ve kullanılırlığını değerlendirmek yalnızca kullanıcının sorumluluğundadır. Uygulamaya üye olan ya da ziyaret eden kullanıcıların bilgileri, şirket tarafından gerekli görüldüğü takdirde üçüncü kişilerle paylaşılabilir. Kişisel bilgiler, gerektiğinde kullanıcıyla iletişime geçmek için de kullanılabilir.

4.2. PW tarafından talep edilen bilgiler veya kullanıcı tarafından sağlanan bilgiler veya mobil uygulama üzerinden yapılan işlemlerle ilgili bilgiler, PW ve iş birliği içinde olduğu kişiler tarafından, PW’nin üyeleri ve müşterileri ile yaptığı sözleşmeler ile belirlenen amaçlar ve kapsam dışında olsa dahi kullanıcının kimliği ifşa edilmemek kaydıyla, çeşitli istatistiksel değerlendirmeler yapmak, veritabanı oluşturmak, pazar araştırmaları yapmak veya benzer sebeplerle de kullanılabilir. PW, mobil uygulama dahilinde başka uygulamalara link verebilir. Şirket, link vasıtasıyla erişilen uygulamaların gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.

MADDE 5 – KARŞILIKLI HAK VE YÜKÜMLÜLÜKLER

5.1. Kullanıcı Hak ve Yükümlülükleri:

a) Kullanıcı, kullanıcı prosedürlerini yerine getirirken, uygulamadaki ürün veya hizmetlerden faydalanırken ve uygulamadaki ürün veya hizmetler ile ilgili herhangi bir işlemi yerine getirirken işbu sözleşme içerisinde yer alan tüm şartlara ve yürürlükteki tüm mevzuata uygun hareket edeceğini, işbu sözleşmede belirtilen tüm şart ve kuralları okuduğunu, anladığını ve onayladığını kabul eder.

b) Kullanıcı, PW’nin yasal mevzuat gereği resmi makamlara açıklama yapmakla yükümlü olduğu durumlarda kullanıcılara ait, uygulama dahilinde edinilen bilgileri resmi makamlara açıklamaya yetkili olacağını ve bu sebeple kendisinden her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul eder.

c) Kullanıcı, uygulamaya erişmek için kullandığı sisteme erişim araçlarını (kullanıcı adı, şifre, kod gibi) gizli tutmakla yükümlüdür. Sisteme erişim araçlarının üçüncü bir şahıs tarafından kullanımının sonuçlarından tamamı ile Kullanıcı sorumludur. PW, usulsüz giriş yapan/yapmaya çalışan kişilerin kimliklerini tespit etmekle yükümlü değildir.

d) PW, kullanıcı tarafından PW’ye iletilen veya uygulama üzerinden kendisi tarafından yüklenen, değiştirilen ve sağlanan bilgilerin ve içeriklerin doğruluğunu araştırma, bu bilgi ve içeriklerin güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti etmekle yükümlü ve sorumlu olmadığı gibi, söz konusu bilgi ve içeriklerin yanlış veya hatalı olmasından dolayı ortaya çıkacak hiçbir zarardan da sorumlu değildir.

e) Kullanıcı işbu uygulamanın PW’ye ait olduğunu ve onun tarafından işletildiğini kabul eder. İşbu uygulamanın içeriği Fikri ve Sınai haklar doğrultusunda korunmaktadır. Kullanıcı, PW ve/veya başka bir üçüncü şahsın aynı veya şahsi haklarına, malvarlığına tecavüz teşkil edecek nitelikteki uygulama dahilinde bulunan resimleri, metinleri, görsel ve işitsel imgeleri, video klipleri, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul ve taahhüt etmektedir.

Uygulamanın (genel görünüm, tasarım, metin, imge, logo, ikon, yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan teknik verileri, PW markası, uygulanan iş metodu ve iş modeli, yazılım kodu ve diğer kodlar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla) tüm unsurları PW’ye aittir ve/veya PW tarafından üçüncü bir kişiden alınan telif hakkı altında kullanılabilecektir.

Fikri ve Sınai haklar kapsamında korunan PW’ye ait tüm bu unsurlar önceden ve yazılı olarak izin alınmadan ve kaynak gösterilmeden değiştirilemez, kopyalanamaz, çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, yeniden satılamaz, paylaşılamaz, dağıtılamaz, sergilenemez, Kullanıcı Sözleşmesi kapsamı dışında kullanılamaz veya bunlardan türemiş çalışmalar yapılamaz veya hazırlanamaz, aksi şekilde davranılması halinde, sorumlu kişi/kişiler PW’nin uğradığı zararı ve/veya lisans verenler de dahil üçüncü kişilerin uğradıkları zararlardan dolayı PW’den talep edilen tazminat miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere karşılamakla yükümlü olacaklardır.

Ayrıca bu yükümlülüğün ihlalinin tespit edilmesi halinde PW, KULLANICI’NIN üyeliliği tek taraflı olarak feshederek, sisteme girişini engelleme hakkına sahiptir. Bu halde PW’nin kullanıcıdan uygulama içinde elde ettiği verileri kullanmaya devam etme hakkı saklıdır.

f) Uygulamada ve uygulama üzerinden link verilen internet sitelerinde, kullanıcı da dahil olmak üzere üçüncü kişiler tarafından yayınlanan içeriklerden dolayı PW’nin sorumluluğu bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri gerçekleştiren kişilerin sorumluluğundadır. PW, üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu ve hukuka uygunluğunu taahhüt ve garanti etmemektedir.

g) Kullanıcı ve PW birbirinden bağımsız taraflardır. İşbu sözleşmenin taraflarca onaylanması ve uygulanması, aralarında ortaklık, temsilcilik veya işçi – işveren ilişkisi olduğu sonucunu doğurmaz.

h) Kullanıcı işbu sözleşme kapsamında üçüncü kişilere referans olmak istemesi halinde, göndereceği mesajlarının hukuka ve ahlaka aykırı unsurlar içermeyeceğini kabul, beyan ve taahhüt eder. Kullanıcı, göndereceği elektronik postalar ile üçüncü kişilerce istenmeyen elektronik posta iletişimi olarak nitelendirilebilecek yoğunlukta bir iletişimde bulunmayacağını kabul, beyan ve taahhüt eder. Kullanıcı, bu madde hükmüne aykırı olarak yapacağı bütün iletişimin sorumluluğunun kendisine ait olduğunu, her ne sebeple olursa olsun bu iletişimlerden kaynaklı olarak PW’ye bir dava ve talep yöneltilmesi halinde; bu dava ve talebin PW tarafından kendisine ihbar edilebileceğini, PW’nin savunma yapması için gerekli olan her türlü bilgi ve içeriği kendisine savunma için gerekli yasal sürelerin geçirilmemesini teminen derhal sağlayacağını, bahsi geçen dava ve talep sonucunda PW’nin herhangi bir zarara uğraması durumunda bahsi geçen zararın PW tarafından, tazminat hakları saklı kalmak kaydıyla, kendisine rücu ettirilebileceğini kabul, beyan ve taahhüt eder. Kullanıcının işbu maddeye aykırı hareket etmesi sebebiyle PW işbu sözleşmeyi herhangi bir bildirimde bulunmadan tek taraflı olarak feshetme, üyeliği askıya alma veya iptal etme ve kullanıcının erişimini engelleme hakkını saklı tutmaktadır.

ı) Kullanıcı, ilgili formları doldurduktan sonra "Kullanıcı Sözleşmesi 'ni okudum, müzakere ettim ve sözleşme şartlarını kabul ediyorum" maddesini onayladıktan sonra işbu sözleşme kapsamında kullanıcı olma ehliyetine sahip olduğunu beyan ve taahhüt etmektedir. Aksi halde, bu hususun PW tarafından tespit edilmesi durumunda Kullanıcının sözleşmesi derhal feshedilir.

5.2. PW’nin Hak ve Yükümlülükleri

a) PW, uygulamada sunulan ürün ve hizmetleri ve içerikleri her zaman tek taraflı olarak değiştirebilme, kullanıcının sisteme yüklediği bilgileri ve içerikleri kullanıcı da dahil olmak üzere üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır. PW, bu hakkını hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir. PW tarafından kullanıcıdan talep edilen değişiklik ve/veya düzeltme istekleri belirtilen süre içerisinde yerine getirilmediği takdirde doğmuş ve doğabilecek zararlardan PW sorumlu olmayacak ve kullanıcının uygulamayı kullanma hakkını dondurabilme veya sonlandırma hakkına sahip olacaktır.

b) Referans kolaylığı veya çeşitli sebepler nedeniyle uygulama içerisinde PW kontrolünde olmayan bir takım web sitelerine veya içeriklere linkler verilebilir. Bu linkler bahsi geçen web sitesini veya içerik sahibini destekleyici mahiyette olmadığı gibi, web sitesi veya içerikte yer alan bilgilere yönelik herhangi bir türde bir beyan veya garanti niteliği de taşımamaktadır Uygulama üzerindeki linkler vasıtasıyla erişilen portallar, web siteleri, dosyalar ve içerikler, bu linkler vasıtasıyla erişilen portallar veya web sitelerinden sunulan hizmetler veya ürünler veya bunların içeriği hakkında PW’nin herhangi bir sorumluluğu yoktur.

c) PW, uygulamada yer alan kullanıcı bilgilerini hizmetler dışında da kullanıcı güvenliği, kendi yükümlülüğünü ifa ve bazı istatistiki değerlendirmeler ve iç denetimler için veya demografik bilgi olarak reklam ve/veya tanıtım için dilediği biçimde kullanabilir, bunları bir veri tabanı üzerinde tasnif edip muhafaza edebilir.

d) PW, işbu uygulama ve uzantısında mevcut her tür hizmet, ürün, uygulamayı kullanma koşulları ile uygulamada sunulan bilgileri önceden bir ihtara gerek olmaksızın değiştirme, yeniden organize etme, uygulamayı durdurma hakkını saklı tutar. Değişiklikler, uygulamada yayım anında yürürlüğe girer. Kullanıcılar, uygulamanın kullanımı ya da uygulamaya giriş ile bu değişiklikleri de kabul etmiş sayılır. Değişiklikler, PW tarafından belirlenen mecra üzerinden Kullanıcılara duyurulur.

e) PW, uygulama kapsamında verdiği hizmetlerin sürekli aktif ve erişilebilir olacağına dair hiçbir garanti vermemektedir. PW özellikle yargı ve sair yetkili resmi merci karar ve uygulamaları, mücbir sebepler, üçüncü kişilerin sebep olduğu durumlar, Internet bağlantı hizmeti sağlayan kuruluşlardan kaynaklanan aksaklıklar ve gecikmeler ve benzeri dış etkenler, yanlış kullanım, PW’dan kaynaklanabilecek teknik arıza ve sair aksaklıklar, tamir çalışmaları veya diğer yönlendirmeler sonucu meydana gelen aksaklıklar konusunda hiçbir şekilde sorumluluk kabul etmez.

f) PW gerektiğinde, ağın işletim güvenliğinin tehlikede olması, ağa erişimin devamlılığı, ağda, yazılımda veya kayıtlı dosyalarda meydana gelebilecek arızaların önüne geçebilmek, muhtemel aksaklıkları engellemek veya etkisini azaltmak adına ve gerekli gördüğü diğer durumlarda hizmete erişimi sınırlandırabilir veya durdurabilir.

MADDE 6 – HİZMETLER

6.1. PW, uygulama aracılığıyla, abone olunan paket bazında tanımlanacak içerikleri sunacaktır.

MADDE 7 – GİZLİLİK

7.1. PW, kişisel bilgi ve veri güvenliğini önemsemekte ve bu hususta gerekli olan tüm tedbirleri almaya özen göstermektedir. Kullanıcılar da uygulamayı kullanarak işbu gizlilik sözleşmesi hükümlerine uygun davranacaklarını kabul, beyan ve taahhüt ederler.

7.2. İşbu gizlilik hükümleri, uygulamada yer alan tüm içerik hakkında geçerlidir.

7.3. PW, uygulama kapsamında kullanıcı tarafından paylaşılmış olan bilgilere erişebilir. PW, bu bilgileri uygulamanın kullanımına yönelik olarak iştirakçileri ile paylaşabilecektir. Kullanıcı da bu bilgilerin uygulamanın amacına yönelik olarak kendisi ile iletişime geçilmek için kullanılacağını kabul eder.

7.4. Uygulamadaki sistemle ilgili sorunların tanımlanabilmesi ve uygulamada çıkabilecek muhtemel sorunların acil olarak giderilmesi için, PW gerektiğinde kullanıcıların IP adresini, sosyal ağ kullanıcı hesabında kayıtlı bilgileri kaydedebilir ve bu kayıtları anılan bu amaçlarla kullanabilir. Bu IP adresleri, PW tarafından kullanıcılarını ve ziyaretçilerini genel anlamda tanımlamak ve kapsamlı şekilde demografik veri toplayabilmek amacıyla kullanılabilir.

7.5. Uygulama kapsamında elde edilen bilgiler PW ve iş birliği içinde olduğu diğer kişi ve kurumlar ile, kullanıcının kimliği anonim olarak kalmak kaydıyla çeşitli istatistiki değerlendirmeler, izinli pazarlama, veri tabanı oluşturma çabaları ve pazar araştırmaları yapma gibi durumlar dahilinde paylaşılabilir.

7.6. PW, uygulama içerisinde başkaca sitelere link (bağlantı) sağlayabilir, anlaşmalı olduğu 3. partilerin reklamlarını yayınlayabilir, Kullanıcıları reklamlar aracılığıyla reklam veren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. PW, bu bağlantı yoluyla erişilen diğer Uygulamaların gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.

7.7. PW, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar;

• Hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,

• PW’nin, kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,

• Yetkili idari ve/veya adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda Kullanıcılarla ilgili bilgi talep edilmesi halleri,

• Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu haller.

7.8. PW tarafından Uygulamalar içerisinde düzenlenebilecek periyodik veya periyodik olmayan anketlere cevap veren Kullanıcılardan talep edilebilecek bilgiler de PW ve iş birliği içindeki kişi ya da kurumlar tarafından bu kullanıcılara doğrudan pazarlama yapmak, istatistiki analizler ve akademik çalışmalar yapmak ve özel bir veri tabanı oluşturmak amacıyla da kullanılabilecektir.

7.9. PW, işbu gizlilik bildiriminde geçen hükümleri gerekli gördüğü zamanda Uygulamada yayınlamak şartıyla değiştirebilir. PW’nin değişiklik yaptığı gizlilik bildirimi hükümleri Uygulamada yayınlandığı tarihte yürürlüğe girmiş kabul edilir.

MADDE 8 – UYGULANACAK HUKUK VE YETKİLİ MAHKEME

İşbu sözleşmenin uygulanmasında ve yorumlanmasında Türk Hukuku uygulanacaktır. İşbu sözleşmeden dolayı doğan veya doğabilecek her türlü ihtilafın hallinde İstanbul Çağlayan Mahkemeleri ve İcra Daireleri yetkilidir.

MADDE 9 – FİKRİ MÜLKİYET HAKLARI

Uygulama'nın (genel görünüm, tasarım, metin, imge, logo, ikon, demonstratif, yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan teknik verileri, PW markası, uygulanan iş metodu ve iş modeli, yazılım kodu ve diğer kodlar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla) tüm unsurları PW’ye aittir ve/veya PW tarafından üçüncü bir kişiden alınan telif hakkı altında kullanılmaktadır. Fikri ve Sınai haklar kapsamında korunan PW’ye ait tüm bu unsurlar önceden izin alınmadan ve kaynak gösterilmeden değiştirilemez, kopyalanamaz, çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, yeniden satılamaz, paylaşılamaz, dağıtılamaz, sergilenemez, Kullanıcı Sözleşmesi kapsamı dışında kullanılamaz veya bunlardan türemiş çalışmalar yapılamaz veya hazırlanamaz, aksi şekilde davranılması halinde, sorumlu kişi kişiler PW’nin uğradığı zararı ve/veya lisans verenler de dahil üçüncü kişilerin uğradıkları zararlardan dolayı PW’den talep edilen tazminat miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere karşılamakla yükümlü olacaklardır.

MADDE 10 – SÖZLEŞME DEĞİŞİKLİKLERİ

PW, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu kullanıcı sözleşmesini veya herhangi bir hükmünü ve eklerini, uygun göreceği herhangi bir zamanda, uygulamada ilan etmek suretiyle değiştirebilir. İş bu kullanıcı sözleşmesinin değişen hükümleri, ilan edildikleri tarihte veya önceden belirtilen geçerlilik tarihi olması halinde ise geçerlilik tarihinde geçerlilik kazanacak; geri kalan hükümler, aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu kullanıcı sözleşmesi, üyelerin tek taraflı beyanları ile değiştirilemez.

MADDE 11 – MÜCBİR SEBEPLER

11.1. Mücbir sebep kapsamı, salgın hastalık, doğal afet, isyan, savaş, grev, PW’nin gerekli bilgi güvenliği önlemleri almasına karşın uygulama, portal ve sisteme yapılan saldırılar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla PW’nin makul kontrolü haricinde gelişen ve PW’nin gerekli özeni göstermesine rağmen önleyemediği kaçınılamayacak olaylar olarak yorumlanacaktır.

11.2. Mücbir sebep sayılan tüm durumlarda, taraflar işbu sözleşme ile belirlenen edinimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir.

MADDE 12 – KAYITLARIN GEÇERLİLİĞİ

Kullanıcı, işbu sözleşmeden doğabilecek ihtilaflarda PW’nin kendi veri tabanında, sunucularında tuttuğu elektronik ve sistem kayıtlarının, ticari kayıtlarının, defter kayıtlarının, muteber bağlayıcı, kesin ve münhasır delil teşkil edeceğini ve bu maddenin HMK 193. madde anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.

MADDE 13 – SÖZLEŞMENİN FESHİ

İşbu kullanıcı sözleşmesi, kullanıcı uygulamaya üye olduğu sürece yürürlükte kalacak ve taraflar arası hüküm ve sonuçlarını doğurmaya devam edecektir. Ancak sözleşmede yer alan ve PW’nin tek taraflı fesih hakkına başvurduğu hallerde sözleşme sona ermiş sayılacaktır.

Böyle bir fesih halinde dahi KULLANICI, işbu sözleşmenin kendisine yüklediği Fikri Mülkiyet haklarını konusundaki yükümlülüklere ve gizlilik hükümlerine SÜRESİZ olarak uymakla yükümlüdür. Aksi halde, bu yükümlülüklerin ihlali hakkındaki hükümler kıyasen uygulanır.

MADDE 14 – TEBLİGAT ADRESLERİ

14.1. Kullanıcıların, PW’ye bildirdiği elektronik posta adresi, bu sözleşme ile ilgili olarak yapılacak her türlü bildirim için yasal adresin isteneceği elektronik posta olarak kabul edilir.

14.2. Taraflar, mevcut elektronik postalarındaki değişiklikleri yazılı olarak diğer tarafa 3 (üç) gün içinde bildirmedikçe, eski elektronik postalara yapılacak isteklerin geçerli olacağını ve kendilerine yapılmış sayılacağını kabul ederler.

MADDE 15 –YÜRÜRLÜK

15.1. Kullanıcı, işbu sözleşmede yer alan maddelerin tümünü okuduğunu, anladığını, kabul ettiğini ve kendisiyle ilgili olarak verdiği bilgilerin doğruluğunu onayladığını beyan, kabul ve taahhüt eder.

15.2. İşbu sözleşme, kullanıcının, üyelik talebine, elektronik olarak onay verilmesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiş olup, ekleriyle birlikte uygulanacaktır. Ekler ile işbu sözleşme hükümleri arasında çelişki olması halinde işbu sözleşme hükümleri geçerli olacaktır.

15.3. İşbu sözleşme 15 (Onbeş) maddeden oluşmakta olup, kullanıcının sitede yer alan kullanıcı sözleşmesini onaylaması neticesi, tarafların serbest iradeleri ile kurulmuştur.
                `
              )}
            </div>
          </div>
        </div>

        <div className="productivity__center center" style={{marginTop:"20px"}}>
          <div
            className="offer__container h4"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
              Usage Agreement
            </h3>
          </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(
                `
                **ARTICLE 1 – PARTIES**
This agreement and its attachments (ANNEX-1 Privacy Agreement) have entered into force upon mutual agreement between "Parentwiser Software Corporation" located at “Çifte Havuzlar Mah. Eski Londra Asfaltı Street Kuluçka Center A1 Block No:151-1C-1B20 Esenler Istanbul" (hereinafter referred to as "PW") and the User who registered to the Parentwiser mobile application under the terms specified in this agreement.

**ARTICLE 2 – DEFINITIONS**
2.1. Application: Refers to the mobile application named PW, mobile software running on mobile devices, and all virtual platforms where products and services associated with this software are presented (Hereafter referred to as the "Application").
2.2. User: Refers to individuals benefiting from services provided by PW in the application under the conditions stated in this agreement (Hereafter referred to as "User").
2.3. Access Tools: Refers to information known only to the user, such as the username, password, code, which allows access to the user's account management page.
2.4. Communication Channels: Refers to communication mediums like instant notifications, email, SMS, MMS, and phone notifications.
2.5. User Account Management Page: Refers to the special web page where the user performs operations related to their membership, accessed only using access tools (Hereafter shortly referred to as the "Portal").

**ARTICLE 3 – SUBJECT AND SCOPE**
3.1. The subject of this agreement is to determine all products and services currently available and to be offered by PW in the future, the conditions of benefiting from these products and services, and the rights and obligations of the parties.
3.2. By accepting the provisions of this agreement, the User also accepts all statements declared by PW related to the use, membership, and services in the application and portal. The user commits to acting in accordance with all the mentioned issues.

**ARTICLE 4 – APPLICATION USAGE TERMS**
4.1. The User acknowledges that they assume all responsibility and risk arising from the use of the application. It is the sole responsibility of the user to evaluate the accuracy, completeness, and usability of all ideas, advice, services, or other information and materials provided through the application. Information of users who are members or visitors of the application can be shared with third parties if deemed necessary by the company. Personal information can also be used to communicate with the user when needed.
4.2. [Information about how PW handles user data and links to other applications, stating PW has no responsibility over content or privacy practices of linked applications.]

**ARTICLE 5 – MUTUAL RIGHTS AND OBLIGATIONS**
**5.1. User Rights and Obligations:**
a) The User acknowledges that they will act according to all terms mentioned in this agreement, and relevant laws, while using the application.
b) The User accepts that PW will be authorized to disclose user information to official authorities when legally required.
c) [Details about user responsibility for keeping their login information secure and PW's non-obligation to identify unauthorized access attempts.]
d) [Details about how PW is not responsible for the validity of content provided by the user and about intellectual property rights of the application's content.]
e) [Details about the intellectual property rights of the application's content and actions against those who violate these rights.]
f) [Clarifications that PW is not responsible for third-party content on the application or through links.]
g) The User and PW are independent parties. This agreement does not create a partnership, representation, or employer-employee relationship between them.
h) [Details about messages sent by the User to third parties through the application.]

**5.2. Rights and Obligations of PW**
a) PW reserves the right to unilaterally change the products, services, and content offered in the application at any time. PW can restrict and delete information and content uploaded by the user to the system, including from third parties. PW can exercise this right without giving any notice or justification. If the user does not comply with changes and/or corrections requested by PW within the specified period, PW will not be responsible for any damages that have or may arise and will have the right to suspend or terminate the user's right to use the application.
b) For reference convenience or various reasons, links to websites or content not under PW's control may be provided in the application. These links neither endorse the mentioned website or content owner nor carry any kind of declaration or warranty about the information contained in the website or content. PW has no responsibility for portals, websites, files, and content accessed via these links, or for the services or products provided by these linked portals or websites.
c) PW can use user information in the application for purposes beyond services, including user security, fulfilling its obligations, statistical evaluations, internal audits, or as demographic information for advertising/promotion. This data can be categorized and stored in a database.
d) PW reserves the right to change, reorganize, or stop any kind of service, product, terms of use, and information in the application without prior notice. Changes take effect upon publication in the application. Users are deemed to have accepted these changes by using or accessing the application. Changes will be announced to users through a medium determined by PW.
e) PW does not guarantee that the services provided within the scope of the application will be continuously active and accessible.
f) PW can restrict or stop service access when necessary for various reasons.

**ARTICLE 6 - SERVICES**
6.1. Through the application, PW will offer content defined based on the subscribed package.

**ARTICLE 7 - PRIVACY**
7.1. PW values personal data security and takes necessary precautions. Users agree to comply with the provisions of this privacy agreement when using the application.
7.2. This privacy provision applies to all content in the application.
7.3. PW can access user-shared information in the application and may share this information with affiliates.
7.4. PW can log user IP addresses and other details when necessary.
7.5. Information obtained within the application can be shared for various purposes.
7.6. PW can provide links to other sites within the application, publish advertisements of agreed 3rd parties, and redirect users to those sites.
7.7. In certain cases, PW may disclose user information to third parties.
7.8. PW can use information from user surveys for various purposes.
7.9. PW can change privacy provisions by publishing them in the application.

**ARTICLE 8 – APPLICABLE LAW AND JURISDICTION**
Turkish law will apply in the interpretation and implementation of this contract. The Istanbul Çağlayan Courts have jurisdiction over any disputes.

**ARTICLE 9 – INTELLECTUAL PROPERTY RIGHTS**
All elements of the Application belong to PW and/or are used by PW under copyright obtained from a third party.

**ARTICLE 10 – CHANGES TO THE AGREEMENT**
PW can unilaterally change this user agreement, any of its provisions, and its annexes at its sole discretion.
                `
              )}
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Usage;
