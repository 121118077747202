import "./App.css";
import PublicRoute from "./components/Routes/PublicRoute";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    var darkThemeActive = localStorage.getItem("darkMode");

    if (darkThemeActive == null) {
      localStorage.setItem("darkMode", "off");
    }

    // eslint-disable-next-line no-undef
    var switchTheme = $(".js-switch-theme"), body = $("body");

    switch (darkThemeActive) {
      case "on":
        body.addClass("dark");
        localStorage.setItem("darkMode", "on");
        break;
      case "off":
        body.removeClass("dark");
        localStorage.setItem("darkMode", "off");
        break;
      default:
        break;
    }
  }, []);

  return (
    <body>
      <Header />
      <PublicRoute />
      <Footer />
    </body>
  );
}

export default App;
