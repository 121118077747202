import React from "react";
import "../GlobalCss/globalStyle.css";
import images from "../../img/index";

export default function Footer() {
  return (
    <div class="footer">
      <div class="footer__center center">
        <div class="footer__body">
          <div class="footer__wrap">
            <a class="footer__logo" href="/home">
              <img
                class="footer__pic footer__pic_dark"
                src={images.logoDark}
                alt=""
              />
              <img
                class="footer__pic footer__pic_light"
                src="img/logo-light.png"
                alt=""
              />
            </a>
            <div class="footer__text">Türkiyenin ilk ebeveynlik uygulaması</div>
          </div>
          <div class="footer__line">
            <div class="footer__downloads">
              <a
                class="footer__download"
                href="https://play.google.com/store/apps/details?id=com.parentwiser.parentingapp.android&hl=tr&gl=US"
                target="_blank"
              >
                <img src={images.googlePlaySvg} alt="" />
              </a>
              <a
                class="footer__download"
                target="_blank"
                href="https://apps.apple.com/tr/app/parentwiser/id1527425284"
              >
                <img src={images.appStoreSvg} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <div class="footer__copyright">© Parentwiser</div>
          <div>
            <div class="footer__social">
              <a className="header__link" href="/privacy-doc">
                Privacy Terms Of Use
              </a>
            </div>
            <div class="footer__social">
              <a className="header__link" href="/contact-doc">
                Communication Consent
              </a>
            </div>
            <div class="footer__social">
              <a className="header__link" href="/usage-doc">
                Usage Agreement
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
