import { useEffect } from "react";
import AOS from "aos";
import {
  iconAndProblems,
  sampleVideos,
  sampleArticles,
} from "../../data/education.page";

const Training = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div class="page">
      <div class="download">
        <div class="download__center center">
          <div class="stage">Parentwiser</div>
          <div class="download__body">
            <div className="offer__container h4" style={{ color: "white" }}>
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
                Ebeveyn Çözümleri
              </h3>
            </div>
            <div class="review__center" style={{ marginTop: 40 }}>
              <div class="download__info">
                Eğitimler, sorun temelli bilgiler sunar. Eğitimlerdeki
                videoları izleyerek ve makaleleri okuyarak, aileler, çocuklarla
                yaşadığı sorunların temel sebeplerini anlar ve direkt çözümler
                öğrenir. Örneğin iki kardeş arasında kardeş kıskançlığı varsa,
                ebeveyn ‘Kardeş Kıskançlığı’ eğitiminde bulunan videoları
                izleyerek ve makaleleri okuyarak bu sorunu derinlemesine anlar
                ve çözümler öğrenir.
              </div>
              <h2
                class="review__title h3"
                data-aos="animation-scale-top"
                data-aos-duration="600"
              >
                Parentwiser İçerisinde İşlenen Sorun – Temelli Eğitimler
              </h2>
              <div class="stage">
                3 – 18 yaş grubu ebeveynleri için sorun temelli eğitimler
                şunlardır. Her konunun altında birçok alt başlıklar ve sorunlar
                vardır.
              </div>
            </div>
            <div class="download__list">
              {iconAndProblems.map((element) => {
                return (
                  <div class="download__item" href="#">
                    <div class="download__icon">
                      <span>{element.icon}</span>
                    </div>
                    <div class="download__category">{element.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Sample Video Trainings */}
      <div className="pricing">
        <div className="pricing__center center">
          <h1
            className="pricing__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Eğitimlerimizden Video Örnekleri
          </h1>
          <div className="pricing__info">
            Uygulamamıza sürekli yeni konular eklenecektir. Ayrıca bize yazın,
            istediğiniz konuları uygulamamıza ekleyelim.
          </div>
          <div className="pricing__list">
            {sampleVideos.map((element) => {
              return (
                <div className="pricing__item">
                  <div className="pricing__head">
                    <div
                      className="pricing__subtitle"
                      data-aos="animation-scale-top"
                      data-aos-duration="600"
                      data-aos-delay="200"
                    >
                      {element.title}
                    </div>
                    <div className="pricing__text">{element.subtitle}</div>
                    <button
                      onClick={() =>
                        (window.location.href = `/videoArticle?id=${element.id}`)
                      }
                      className="pricing__btn btn btn_orange"
                    >
                      İzle
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Sample Article Trainings */}
      <div className="pricing">
        <div className="pricing__center center">
          <h1
            className="pricing__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            Eğitimlerimizden Makale Örnekleri
          </h1>
          <div className="pricing__info"></div>
          <div className="pricing__list">
            {sampleArticles.map((element) => {
              return (
                <div className="pricing__item">
                  <div className="pricing__head">
                    <div
                      className="pricing__subtitle"
                      data-aos="animation-scale-top"
                      data-aos-duration="600"
                      data-aos-delay="200"
                    >
                      {element.title}
                    </div>
                    <div className="pricing__text">{element.subtitle}</div>
                    <button
                      onClick={() =>
                        (window.location.href = `/article?id=${element.id}`)
                      }
                      className="pricing__btn btn btn_orange"
                    >
                      Oku
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Training;
