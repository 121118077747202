import parser from "html-react-parser";
import { useEffect } from "react";
import AOS from "aos";
const PrivacyPolicy = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="page">
      <div className="productivity">
        <div className="productivity__center center">
        <div className="offer__container h4" style={{ color: "white", marginBottom:"20px" }}>
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
                Gizlilik Sözleşmesi
              </h3>
            </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(
                `
                  Sayın Kullanıcı, <br/>
                  Parentwiser'a hoş geldiniz, Aşağıdaki
                  "Gizlilik Sözleşmesi", Parentwiser’ın, siz değerli
                  kullanıcılarımıza sağladığı bilgi ve hizmetlerin sunumuna
                  ilişkin hükümleri düzenlemektedir. Parentwiser uygulamasına
                  giren ya da sitedeki formları dolduran her kullanıcı "Telif
                  Hakkı Bilgisi", "Gizlilik Sözleşmesi" ve "Kullanım
                  Şartları"nda yer alan hükümleri okumuş ve kabul etmiş
                  sayılacaktır. Gizlilik Politikası 1. Parentwiser kayıtlı ve
                  misafir kullanıcılarına ait e-posta, adı, soyadı, telefon
                  numarası ve kayıt sırasında girilen hiçbir bilgiyi Web
                  Sitesinde yayınlamaz, kullanıcı aksini belirtmediyse hiçbir iş
                  ortağı, şirket, kurum ya da diğer kuruluşla paylaşmaz. 2.
                  Parentwiser kişisel bilgilerinizi sadece aşağıda belirtilen
                  yasal durumlarda ve yasal prosedürler çerçevesinde 3.
                  şahıslara açar. a.) Yasal mercilerden bu yönde yazılı bir
                  talep oluşması halinde, b.) Parentwiser’ın mülkiyet haklarını
                  korumak ve savunmak amacıyla, c.) Kullanım şartlarında kabul
                  ettiğiniz kurallar çerçevesinde. 3. Parentwiser'da kayıtlı
                  kişisel bilgileriniz sadece sizin tarafınızdan
                  görüntülenebilir. Bu bilgiler hiçbir şekilde satılmaz,
                  kiralanmaz ya da başka bir kurum ya da kuruluş ile karşılıklı
                  değiştirilmez. Bu "Gizlilik Sözleşmesi" metninde yer alan
                  maddeler haricinde hiçbir şekilde 3. şahıslarla paylaşılmaz.
                  Parentwiser bu sözleşmede taahhüt ettiği şartları yerine
                  getirmek için mümkün olan tüm önlemleri alır. 4. Parentwiser
                  tarafından toplanan bilgiler genel kullanıma açık olmayan
                  güvenli bir ortamda saklanır. Parentwiser, ortamdaki bilgileri
                  korumak için her türlü endüstri standardını kullanmaktadır. 5.
                  Kayıt sırasında girdiğiniz kişisel bilgilerinizi istediğiniz
                  her zaman güncelleme ve değiştirme hakkınız bulunmaktadır.
                  Parentwiser bu "Gizlilik Sözleşmesi" ve "Hizmet Sözleşmesi"ne
                  uymadığınız takdirde hesabınızı silmeye ya da askıya almaya
                  yetkilidir. 6. İnternetin yapısı gereği bilgiler internet
                  üzerinde yeterli güvenlik önlemleri olmaksızın dolaşabilir ve
                  yetkili olmayan kişiler tarafından alınıp kullanılabilir. Bu
                  kullanım ve kullanımdan doğacak zarar Parentwiser’ın
                  sorumluluğunda değildir. 7. Bazı durumlarda, şahsınıza özel
                  olmayan bilgiler toplanabilir. Bu tip bilgilere örnek olarak
                  kullandığınız internet browser' ın türü, işletim sisteminiz,
                  site'mize linkle ya da ilanla ulaştığınız sitenin domain ismi
                  verilebilir. 8. Site'yi ziyaret ettiğinizde bilgisayarınıza
                  bilgi konulabilir. Bu bilgi, bilgi belirteci ("cookie")
                  formatında ya da benzeri türden dosyada olacaktır ve bize
                  birkaç yönden yardımcı olacaktır. Örneğin, cookie'ler,
                  siteleri ve reklamları sizin ilgi alanlarınıza ve
                  tercihlerinize göre düzenlememizi sağlayacaktır. Hemen hemen
                  tüm internet browser'larda, cookie'leri hard diskinizden
                  silmek, yazılmasını önlemek ya da kaydedilmeden önce uyarı
                  mesajı almak için seçenekler vardır. Bu konuda daha fazla
                  bilgi için lütfen browser'ınızın yardım dosyalarına ve
                  kullanım bilgilerine bakınız. 9. IP adresiniz Web Sitesinin ve
                  serverlarımızın sürekli çalışır halde bulundurulması,
                  yönetilmesi ve sorunların çözülmesi amacıyla kullanılmaktadır.
                  Size ait IP adresi sizin tanımlanmanızda kullanılmaktadır. 10.
                  Bu Web Sitesi diğer web sitelerine link vermektedir. Gizlilik
                  Güvencesi sadece bu Web Sitesi içerisinde geçerlidir ve diğer
                  web sitelerini kapsamamaktadır. Bu web sitesinden link ile
                  gidilecek diğer web sitelerindeki kullanım ile ilgili o
                  sitelere ait Gizlilik Güvencesi ve Kullanım Şartları
                  geçerlidir. Bu Web Sitesinden linkle gittiğiniz diğer web
                  sitelerinde o sitelere ait Gizlilik Güvencesi ve Kullanım
                  Şartları metinlerini bularak okumanız önerilir. 11.
                  Parentwiser bu metin içindeki her türlü bilgiyi değiştirme
                  hakkını saklı tutar. Bu siteyi kullanarak bu "Gizlilik
                  Güvencesi" nde yapılacak her türlü düzenleme ve değişikliği
                  kabul etmiş sayılıyorsunuz. Kişisel verileriniz koruma altında
                  Parentwiser olarak, müşterilerimizin özel hayatlarının
                  gizliliğine saygı duyuyor ve önem veriyoruz. Bu nedenle
                  müşterilerimizin kişisel verilerinin kullanılmasında,
                  müşterilerimizin temel hak ve özgürlüklerini korumak amacıyla
                  yürürlükte olan Kişisel Verilerin Korunması Kanunu hakkında
                  bilgi vermek istiyoruz. Biz bu verileri; - Her zaman güvenle
                  saklıyor, - 5809 sayılı Elektronik Haberleşme Kanunu ve ilgili
                  sair mevzuat kapsamında sunulmakta olan ürün ve hizmetlerde, -
                  İlgili mevzuattan kaynaklanan saklama, bilgilendirme,
                  raporlama ve ilgili diğer yükümlülüklerin yerine
                  getirilmesinde, - Bilgi Teknolojileri ve İletişim Kurumu ve
                  diğer ilgili görevli/yetkili mercilerin bilgi ve belge
                  taleplerinin karşılanmasında, - Şirketimizin diğer ürün /
                  hizmetlerini sunulabilmesi için, - Akdedilen hizmet
                  sözleşmesinin gereğinin yerine getirilmesinde kullanıyor, -
                  Kanunun izin verdiği kuruluşlar dışında kimseyle
                  paylaşmıyoruz. Dilediğiniz zaman bize başvurarak; - Kişisel
                  verilerinizin kullanılma amacını, - Hangi kuruluşlarla ve ne
                  amaçla paylaşıldığını öğrenebilirsiniz. - Eksik ya da hatalı
                  kaydedilen ya da kullanılan bilgilerinizin düzeltilmesini, -
                  Kanunda öngörülen şartlar sağlanmışsa bilgilerinizin
                  silinmesini ya da yok edilmesini, - Bilgilerinizin kanuna
                  aykırı olarak kaydedildiğini veya kullanıldığını
                  düşünüyorsanız ve bu sebeple zarara uğramışsanız zararın
                  giderilmesini isteyebilirsiniz. Kanun kapsamındaki
                  haklarınızdan yararlanmak için başvurularınızı yazılı olarak
                  kimlik fotokopinizle birlikte info@parentwiser.com adresine
                  gönderebilirsiniz, detaylı bilgi almak için TBMM’nin resmi
                  internet sitesinden kanunu inceleyebilirsiniz. *6698 sayılı
                  Kişisel Verilerin Korunması Kanunu (“KVKK”)
                `
              )}
            </div>
          </div>
        </div>
        <div className="productivity__center center" style={{marginTop:"20px"}}>
        <div className="offer__container h4" style={{ color: "white", marginBottom:"20px" }}>
              <h3 data-aos="animation-scale-top" data-aos-duration="600">
              Privacy Agreement
              </h3>
            </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(
                `
Dear User,<br/>
Welcome to Parentwiser. The "Privacy Agreement" below regulates the provisions related to the services and information provided by Parentwiser to our esteemed users. Every user who enters the Parentwiser application or fills out forms on the site is considered to have read and accepted the provisions in the "Copyright Information", "Privacy Agreement", and "Terms of Use". Privacy Policy:
Parentwiser does not publish any information such as email, first name, last name, phone number, and other details entered during registration on the Website and, unless the user indicates otherwise, does not share it with any partner, company, institution, or other organization.
Parentwiser only discloses your personal information to third parties in the legal situations specified below and within legal procedures.
a.) When there is a written request from legal authorities,
b.) To protect and defend the proprietary rights of Parentwiser,
c.) Within the rules you accepted in the terms of use.
Your personal details registered with Parentwiser can only be viewed by you. This information is in no way sold, rented, or exchanged with another institution or organization. It is not shared with third parties, except for the provisions mentioned in this "Privacy Agreement". Parentwiser takes all possible precautions to fulfill the commitments stated in this agreement.
Information collected by Parentwiser is stored in a secure environment that is not open to general use. Parentwiser uses every industry standard to protect the information in the environment.
You have the right to update and modify the personal information you entered during registration at any time. Parentwiser has the right to delete or suspend your account if you do not comply with this "Privacy Agreement" and "Service Agreement".
Due to the nature of the Internet, information can circulate on the Internet without sufficient security measures and can be taken and used by unauthorized persons. This usage and any damage arising from this use is not the responsibility of Parentwiser.
In some cases, non-personal information can be collected. Examples of this type of information include the type of internet browser you use, your operating system, and the domain name of the site you accessed our site from, either by link or advertisement.
When you visit the Site, information may be placed on your computer. This information will be in the form of an information indicator ("cookie") or a similar type of file, and will help us in several ways. For example, cookies allow us to organize sites and advertisements according to your interests and preferences. Almost all internet browsers have options to delete cookies from your hard drive, prevent writing, or receive a warning message before being saved. For more information, please refer to your browser's help files and usage information.
Your IP address is used to keep our Website and our servers continuously running, for management, and for problem-solving. Your IP address is used to identify you.
This Website provides links to other websites. The Privacy Assurance is only valid within this Website and does not cover other websites. Privacy Assurance and Terms of Use of the sites to be accessed via links from this website are valid. It is recommended to read the Privacy Assurance and Terms of Use texts of other websites that you go to via links from this Website.
Parentwiser reserves the right to change any information within this text. By using this site, you are deemed to have accepted any modifications and changes to this "Privacy Assurance". Your personal data is protected. As Parentwiser, we respect and value the privacy of our customers' private lives. Therefore, regarding the use of our customers' personal data, we want to provide information about the currently effective Personal Data Protection Law. We keep this data;
Always safely,
In the context of the products and services provided under the Electronic Communications Law No. 5809 and related legislation,
In meeting the storage, information, reporting, and other obligations arising from relevant legislation,
In meeting the information and document requests of the Information Technologies and Communication Authority and other relevant authorities/agencies,
For the delivery of other products/services of our company,
We use it to fulfill the requirements of the signed service contract,
We do not share it with anyone except organizations allowed by the law. You can apply to us at any time and;
Learn the purpose of using your personal data,
Learn which organizations and for what purpose it is shared,
Request the correction of your information that is recorded or used incorrectly or incompletely,
Request the deletion or destruction of your data if the conditions stipulated by the law are met,
If you believe your data was recorded or used in violation of the law and have suffered damage due to this reason, you can request compensation for the damage.
To benefit from your rights under the law, you can send your written applications with a photocopy of your ID to info@parentwiser.com. For detailed information, you can review the law on the official website of the TBMM. * Personal Data Protection Law No. 6698 ("KVKK")

                `
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
