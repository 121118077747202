import parser from "html-react-parser";
import { useEffect } from "react";
import AOS from "aos";

const ContactDoc = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="page">
      <div className="productivity">
        <div className="productivity__center center">
          <div
            className="offer__container h4"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
              İletişim İzni
            </h3>
          </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(`
              
PARENTWİSER MOBİL UYGULAMASI KULLANICI BİLGİLENDİRME VE 

KİŞİLER VERİLER HAKKINDA AYDINLATMA METNİ 



​6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında Veri Sorumlusu sıfatıyla ​Çifte Havuzlar Mah.Eski Londra Asfaltı Cad.N:151/1c/1b20 ESENLER adresinde mukim Parentwiser Yazılım Anonim Şirketi t​arafından işbu aydınlatma metni düzenlenmiştir. 

Parentwiser Yazılım Anonim Şirketi ​DİJİTAL EBEVENLİK PROJESİ KAPSAMINDA, geliştirilen mobil uygulamada üyelik esasına dayalı olarak bilgi paylaşımı yapan ebeveynlerin, 

 ​“Adı – soyadı, iletişim bilgileri, telefon numarası, e-mail adresi, adres bilgileri, medeni durumu, çocuk sayısı vb. vb. vb. vb.” ilişkin kişisel veriler mevzuat kaynaklı yükümlülüklerin yerine getirilmesi, akademik süreçlerinin yürütülmesi maksadıyla, fiziki ve elektronik ortamlarda bilgi ve belge oluşturulması şeklinde tamamen veya kısmen otomatik ve otomatik olmayan yollarla işlenmektedir. 

Parentwiser Yazılım Anonim Şirketi ​olarak tarafımızdan işlenen kişisel veriler, araştırma geliştirme faaliyetlerinin yürütülmesi, veri kategorizasyonu, akademik çalışmalar amacıyla “İş ortaklıklarına (Ar-ge şirketleri)” ​aktarılmaktadır. 

6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesi uyarınca aşağıdaki haklara sahip bulunmaktasınız. 



- Kişisel verilerinizin işlenip işlenmediğini öğrenme, kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme, kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme, kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nda öngörülen koşullara uygun olarak kişisel verilerinizin silinmesini veya yok edilmesini isteme, eksik veya yanlış olarak işlenmiş kişisel verilerinizin düzeltildiğinin veya kişisel verilerinizin silindiğinin ya da yok edildiğinin kişisel verilerinizin aktarıldığı 3. kişilere bildirilmesini isteme, işlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonuç ortaya çıktığına inandığınız hallerde,otomatik yollarla işleme neticesinde çıkan sonuçlara itiraz etme, kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarınız bulunmaktadır.



Veri Sorumlusuna Başvuru Usul ve Esaslar Hakkında Tebliğ hükümleri uyarınca Veri Sorumlusu olan ​Parentwiser Yazılım Anonim Şirketi’nin adresine 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde belirtilen haklara ilişkin taleplerinizi yazılı olarak bildirebilirsiniz. `)}
            </div>
          </div>
        </div>

        <div className="productivity__center center" style={{marginTop:"20px"}}>
          <div
            className="offer__container h4"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
            Communication Consent
            </h3>
          </div>
          <div className="productivity__container">
            <div
              className="productivity__details"
              style={{ textAlign: "start" }}
            >
              {parser(`
              This explanatory text on user information and personal data for the PARENTWISER MOBILE APPLICATION has been prepared by ParentWiser Yazılım Anonim Şirketi, which resides at Çifte Havuzlar Mah.Eski Londra Asfaltı Cad.N:151/1c/1b20 ESENLER, as the Data Controller, within the context of Article 10 of Law No. 6698 on the Protection of Personal Data and the Regulations Regarding the Methods and Principles of Fulfilling the Disclosure Requirement.

              Under the DIGITAL PARENTING PROJECT, in the mobile application developed by ParentWiser Yazılım Anonim Şirketi, personal data of parents who share information based on membership, including "Name – surname, contact details, phone number, e-mail address, address information, marital status, number of children, etc." is being processed, either fully or partially, in automated and non-automated methods, for fulfilling legally required obligations and conducting academic processes.
              
              Personal data processed by us at ParentWiser Yazılım Anonim Şirketi is transferred for research and development activities, data categorization, and academic studies to "Business partners (R&D companies)".
              
              According to Article 11 of Law No. 6698 on the Protection of Personal Data, you have the following rights:
              
              - To learn if your personal data is processed and to request related information if it has been processed.
              - To understand the purpose of processing your personal data and to check if they are used appropriately.
              - To know the third parties, within or outside the country, to whom your personal data is transferred.
              - To request the rectification of incomplete or inaccurate data.
              - To request the deletion or destruction of personal data in accordance with the conditions set forth in the Law No. 6698 on the Protection of Personal Data.
              - To demand that the third parties to whom your personal data has been transferred be informed about rectifications or deletions.
              - To object to any unfavorable results arising due to the analysis of the processed data exclusively through automated systems.
              - To demand compensation in case you suffer damages due to the illegal processing of your personal data.
              
              According to the regulations on the methods and principles for applications to the Data Controller, you can submit your requests regarding the rights specified in Article 11 of the Law No. 6698 on the Protection of Personal Data in writing to the address of ParentWiser Yazılım Anonim Şirketi, which is the Data Controller.
              `)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDoc;
