export const faq = [
    {
        question:"Parentwiser’a nasıl üye olunur?",
        answer:"Parentwiser’ı Google Play Store ve App Store’dan indirerek; mail adresi ve oluşturacağınız şifre ile kolayca üye olabilirsiniz."
    },
    {
        question:"PW Kullanıcı Paneli Nedir?",
        answer:"Parentwiser Kullanıcı Paneli uygulamaya kayıt olduğunuz e-posta adresi ve şifreniz ile giriş sağlayarak hesap işlemlerinizi düzenleyebileceğiniz bir sayfadır."
    },
    {
        question:"Parentwiser içeriklerine neden kullanıcı panelinden ulaşamıyorum?",
        answer:"Parentwiser Kullanıcı Paneli yalnızca hesap işlemlerinizi düzenlemek için kullanabileceğiniz bir sayfadır, içerikleri görüntülemek için Parentwiser uygulamasını Google Play veya App Store’dan indirmelisiniz."
    },
    {
        question:"Üyelik planlarınız hakkında bilgi almak istiyorum.",
        answer:"Parentwiser’da yer alan içerikler siz değerli kullanıcılarımız için 1 yıl boyunca faydalanılabilecek şekilde düzenlenmiştir, bu bağlamda  üyelik planımız 1 yıllıktır."
    },
    {
        question:"Mevcut üyeliğimi nereden yenileyebilirim? İçeriklere kaldığım yerden devam edebilir miyim?",
        answer:"Üyeliğiniz 1 yıl sonunda Premium hesaptan ücretsiz hesaba geçer. PW Kullanıcı Paneli’nden üyeliğinizi yenileyebilir ve içerik tüketiminize kaldığınız yerden devam edebilirsiniz."
    },
    {
        question:"Uygulamaya birden fazla çocuk eklenebilir mi? Her çocuk için ayrı hesap mı oluşturmalıyım?",
        answer:"Parentwiser’a birden fazla çocuk ekleyebilirsiniz. Çocuklarınızın yaş ve sınıflarının farklı olması farketmeksizin tüm içeriklere ulaşabilirisiniz."
    },
    {
        question:"Premium hesap kullanıyorum fakat Anne Baba Okulu içeriklerinin hepsini görüntüleyemiyorum.",
        answer:"Parentwiser’da yer alan içerikler siz değerli kullancılarımız için 1 yıl boyunca faydalanılabilecek şekilde oluşturulmuştur. Anne Baba okulu içerikleri de 52 modülden oluşmaktadır, bu bağlamda her hafta 1 modül açılıyor olup içerikler 1 yıl boyunca tüketilebilir şekildedir."
    },
    {
        question:"Yurt dışında ikamet ediyorum, uygulamayı nasıl satın alabilirim?",
        answer:"Parentwiser’ı Türk Lirası, Dolar, Euro, Sterlin para birimi üzerinden olan tüm banka ve kredi kartları ile satın alabilirsiniz."
    }
]