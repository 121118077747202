import React from "react";

const videoCard = (props) => {

  return (
    <div className="productivity__item">
      <div className="productivity__details">
        <div
          className="productivity__category"
          data-aos="animation-scale-top"
          data-aos-duration="600"
        >
          {props.title}
        </div>
        <iframe
          className="videoPlayerSize"
          src={props.url}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default videoCard;
