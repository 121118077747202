import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Contact,
  Training,
  ParentSchool,
  KnowYourself,
  VideoArticle,
  Article,
  Survey,
  PrivacyPolicy,
} from "../../pages";
import QuestionCards from "../../pages/QuestionCards/QuestionCards";
import Kvkk from "../../pages/RequiredTexts/Kvkk";
import Usage from '../../pages/RequiredTexts/Usage'
import ContactDoc from '../../pages/RequiredTexts/ContactDoc'

export default function PublicRoute() {
  return (
    <BrowserRouter>
      <Routes path="/" component={<Home />}>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="training" element={<Training />} />
        <Route path="parentSchool" element={<ParentSchool />} />
        <Route path="knowYourself" element={<KnowYourself />} />
        <Route path="videoArticle" element={<VideoArticle />} />
        <Route path="article" element={<Article />} />
        <Route path="survey" element={<Survey />} />
        <Route path="gizlilik-politikasi" element={<PrivacyPolicy />} />
        <Route path="privacy-doc" element={<PrivacyPolicy />} />
        <Route path="kvkk-doc" element={<Kvkk />} />
        <Route path="usage-doc" element={<Usage />} />
        <Route path="contact-doc" element={<ContactDoc />} />
        <Route path="questionCards" element={<QuestionCards />} />
      </Routes>
    </BrowserRouter>
  );
}
