export const surveyList = [
"Ebeveyn Türleri Anketi",
"Bağlanma Türleri Anketi",
"Çocuğun Bağlanma Türleri Anketi",
"Motivasyon Kaynakları Anketi",
"Koşullu Sevgi Anketi",
"Optimizm Anketi",
"Aşırı Koruyucu Ebeveynlik Anketi",
"Ebeveynin Öz Yeterliliği Anketi",
"İçsel Onay İhtiyacı Anketi ",
"Aşırı Rekabet Eğilimi Anketi",
"Belirsizlik Toleransı Anketi",
"Öğrenme Eğilimi Anketi",
"Öz Değer Anketi",
"Empati Düzeyi Anketi",
"Mükemmeliyetçilik Anketi",
"Sınır Koyan Aile Anketi",
"Baskıcı Davranış Gösteren Aile Anketi",
"Özerklik Desteği Gösteren Aile Anketi",
"Tutarlı Ebeveyn Anketi",
"Çocuğu ile Yakınlık Kurabilen Ebeveyn Anketi",
"Yaşam Disiplini Anketi",
"Öğrenme Eğilim Düzeyi Anketi",
"Yargılanma Korkusu Anketi",
"Güç İhtiyacı Anketi",
"Başarı İhtiyacı Anketi",
"Bağlanma İhtiyacı Anketi",
"Dış Motivasyon Düzeyi Anketi",
"Reddedici Tutum Gösteren Aile Anketi",
"Aile İçi Düzeni Kurabilen Ebeveyn misiniz?"
]

export const sampleSurveys = [
    {
      title:"Tutarlı Ebeveyn Anketi",
      subtitle:"",
      id:101
    },
    {
      title:"Mükemmeliyetçilik Anketi",
      subtitle:"",
      id:102
    }
  ]