import React from "react";
import Modal from "react-modal";
import images from "../../../img/index";

const DownloadModal = (props) => {
  return (
    <Modal
      isOpen={props.modalOpen}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={closeModal}
      style={{ overflow: "hidden" }}
      className="mfp-container"
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="mfp-bg"></div>
      <div className="mfp-wrap">
        <div className="mfp-container">
          <div className="mfp-content">
            <div className="popup" id="popup-sign-up">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{ color: "white", fontSize: 22 }}
                  onClick={() => props.setModalOpen(false)}
                >
                  X
                </button>
              </div>
              <div className="popup__title h2">Parentwiser’ı ücretsiz indirin</div>
              <div className="popup__variants">
                <a className="popup__variant" href="https://play.google.com/store/apps/details?id=com.parentwiser.parentingapp.android&hl=tr&gl=US" target="_blank">
                  <img src={images.googlePlayIconSvg} alt="" />
                  Google Play
                </a>
                <a className="popup__variant" href="https://apps.apple.com/tr/app/parentwiser/id1527425284?l=tr" target="_blank">
                  <img src={images.appleSvg} alt="" />
                  App Store
                </a>
              </div>
              <div className="popup__note"> </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
