import React, { useEffect, useState } from "react";
import AOS from "aos";
import Loading from "../../components/Loading/Loading";
import "../../components/GlobalCss/globalStyle.css";
import "../../components/GlobalCss/fontStyle.css";
import {kardesKiskancligi, sinavKaygisi} from '../../data/text.article.data'

const Article = () => {

  const [data, setData] = useState({})


  useEffect(() => {
    AOS.init();
    const queryParams = new URLSearchParams(window.location.search);
    const articleId = queryParams.get("id");
    
    switch (articleId) {
      case "3":
        setData(sinavKaygisi)
        break;
      case "4":
        setData(kardesKiskancligi)
        break;
      default:
        setData(sinavKaygisi)
        break;
    }

  }, []);

  return (
    <div className="page">
      <div className="download">
        <div className="download__center center">
          <div className="offer__container h4">
            <h3 data-aos="animation-scale-top" data-aos-duration="600">
                <span style={{ color: "#ffffff" }}>
                {data?.title}
                </span>
            </h3>
          </div>
        </div>
        <div className="review__center" style={{ marginTop: 40 }}>
          <div class="download__info">
              {data?.description}
          </div>
        </div>
      </div>
      <div className="productivity">
        <div className="productivity__center center">
          <div className="productivity__container">
            <div className="productivity__row">
              <div className="productivity__list">
                {
                  data?.articles?.map((element) => {
                    return (
                      <div className="productivity__item">
                        <div className="productivity__details">
                          <div
                            className="productivity__category"
                            data-aos="animation-scale-top"
                            data-aos-duration="200"
                          >
                            {element?.articleTitle}
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="options__center center">
        {console.log("==>",data)}
        <div className="advantages__head">
          <h2
            className="advantages__title h2"
            data-aos="animation-scale-top"
            data-aos-duration="600"
          >
            {
              data?.previewTitle
            }
          </h2>
        </div>
      </div>

      <div className="download__center center">
        <div className="download__body">
          <div className="review__center" style={{ marginTop: 40 }}>
            <div className="download__info">
            {
              data?.previewArticle
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
